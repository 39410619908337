import React, { useEffect, useState } from "react";
import { TextTitle, FlexibleContainer, Button } from "./styled"
import { BaseViewContainer } from "containers/main/baseViewContainer";
import { Cart } from "store/product/interface";
import { CartProductData, useManageCartItems } from "store/product/cart";
import currencyFormatter from "utils/currencyFormatter";
import { toast } from "react-toastify";
import { CartTableCell } from "./components/cartTableCell";
import _ from "lodash";
import { PrimaryButton } from "components/buttons";
import { useNavigate } from "react-router";


export const CartPage = () => {
    const navigate = useNavigate()
    const { setItems, getItems, removeItem, isLoading: isCartLoading } = useManageCartItems();
    const [items, setCartItems] = useState<CartProductData[]>([]);
    const cartTotal = items.reduce((total, item) => (total + (item.quantity * item.amount)), 0);
    const [startLoading, setStartLoading] = useState(false);

    useEffect(() => setCartItems(getItems()), [])

    const initCartRemoveItem = (item: CartProductData) => {
        if (window.confirm('You are about to remove this item from your cart, click ok to continue.')) {
            removeItem(item).then((response) => {
                if (response) toast.success(response as string);
            }).catch((error) => {
                console.log(error);
                toast.error(error);
            }).finally(() => {
                setCartItems(getItems())
            })
        }
    }

    const setUpdatedQuantity = (quantity: number, index: number) => {
        setStartLoading(true)
        let newItems = [...items];
        newItems[index].quantity = quantity;

        // console.log(quantity, index, newItems[index])

        const run = _.debounce(() => {
            setCartItems(newItems);
            setItems(newItems).then((status) => {
                if (!status) toast.error('Error updating cart information');
            });
            setStartLoading(false)
        }, 300);

        run();
    }

    const goToCheckout = () => {
        navigate('/checkout', { state: { items } });
    }



    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <FlexibleContainer margin="3em 3em 3em 7.5em">
                <TextTitle className="mb-3">Cart</TextTitle>
                <div className="table-responsive cart-checkout">
                    <table className="table ">
                        <thead className="py-2">
                            <tr>
                                <th style={{ minWidth: '200px' }}>Item</th>
                                <th className="d-md-block d-none">Quantity</th>
                                <th className="text-end">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(items.length) ? (
                                (items).map((item, index) => (
                                    <CartTableCell
                                        key={index}
                                        item={item}
                                        removeItem={initCartRemoveItem}
                                        isLoading={startLoading || isCartLoading}
                                        onChangeQuantity={(quantity) => setUpdatedQuantity(quantity, index)}
                                    />
                                ))
                            ) : (
                                <tr className="py-3">
                                    <td colSpan={3} className="py-4">
                                        <p className="text-muted text-center fw-bold py-4">
                                            No items in cart.
                                        </p>
                                    </td>
                                </tr>
                            )}
                            <tr className="py-3">
                                <td style={{padding: "2.5em"}} className="d-md-block d-none"></td>
                                <td className="text-start">Sub Total</td>
                                <td className="text-end"><strong className="text-theme-primary">₦{currencyFormatter(cartTotal)}</strong></td>
                            </tr>
                            <tr className="py-3">
                                <td style={{padding: "2.5em"}} className="d-md-block d-none"></td>
                                <td className="text-start">Shipping</td>
                                <td colSpan={2} className="text-end"><strong className="text-theme-primary">Calculated at Checkout </strong></td>
                            </tr>
                            <tr className="py-3">
                                <td style={{padding: "2.5em"}} className="d-md-block d-none"></td>
                                <td className="text-start">Grand Total</td>
                                <td className="text-end"><strong className="text-theme-primary">₦{currencyFormatter(cartTotal)}</strong></td>
                            </tr>
                            <tr className="py-3">
                                <td colSpan={3}>
                                    <PrimaryButton
                                        style={{width: "57%"}}
                                        className="btn-block float-md-end float-start"
                                        onClick={goToCheckout}
                                        disabled={startLoading || isCartLoading || !items.length}
                                    >
                                        Check Out
                                    </PrimaryButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </FlexibleContainer>
        </BaseViewContainer>
    )
}   