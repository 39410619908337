export const validateEmail = (email: string) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return (email.match(validRegex)) ? true : false;
}

export const validationError = (errors: object) => {
    let error = "";
    if (typeof errors !== "object") return errors;
    Object.entries(errors).forEach(([key, value]) => {
        error = value.join(", ");
        if (error.length) { return; }
    })
    return (error.length) ? error : null;
};

export const isValidURL = (value: string): boolean => {
    var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    return (res !== null);
};