import styled from '@emotion/styled';
import { Accordion, Container } from 'react-bootstrap';
import { PrimaryButton, OutlinedButton } from "components/buttons"

export const HeroText = styled.h1(`
    font-weight: 900;
    font-size: 56px;
    line-height: 104%;
    color: #090C11;

    @media (max-width: 768px) {
        font-size: 48px;
    }

    @media (max-width: 576px) {
        font-size: 42px;
    }
`)

export const TextTitle = styled.h1(`
    font-weight: 900;
    font-size: 48px;
    line-height: 104%;
    color: #090C11;

    @media (max-width: 768px) {
        font-size: 42px;
    }

    @media (max-width: 576px) {
        font-size: 32px;
    }
`)

export const SubText = styled.p(`
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    color: #383B40;

    @media (max-width: 576px) {
        font-size: 16px;
    }
`)

interface FrameProps {
    padding?: string;
    margin?: string;
    maxWidth?: string;
    height?: string;
    alignItems?: boolean;
}

export const FlexibleContainer = styled(Container)<FrameProps>`
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    align-items: ${props => props.alignItems ? "center" : "unset"};
    justify-content: center;
    max-width: ${props => props.maxWidth ? props.maxWidth : '85%'} !important;

    @media (max-width: 992px) {
        padding: 1em !important;
        margin: 2em 0 2em !important;
        max-width: 90% !important;
    }

    @media (max-width: 768px) {
        padding: 0.5em !important;
        margin: 0.5em !important;
        max-width: 90% !important;
    }

    @media (max-width: 576px) {
        padding: 0 !important;
        margin: 2em 0 2em !important;
        max-width: 100% !important;
    }
`

export const ThemeAccordion = styled(Accordion)`
    .accordion-item {
        margin: 0.5em 0 0.5em 0;
        border: none !important;
    }

    .accordion-button:not(.collapsed) {
        color: #000 !important;
        background-color: #fff !important;
        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    }

    .accordion-button:focus {
        z-index: 3;
        border-color: aliceblue;
        outline: 0;
        box-shadow: none;
    }
`

interface ButtonProps {
    lgWidth?: string;
    smWidth?: string;
}

export const Button = styled(PrimaryButton)<ButtonProps>`
    padding: 10px !important;
    width: ${props => props.lgWidth ?? "50%"} !important;

    @media (max-width: 576px) {
        width: ${props => props.smWidth ?? "70%"} !important;
    }
`

export const BorderedButton = styled(OutlinedButton)<ButtonProps>`
    padding: 10px !important;
    width: ${props => props.lgWidth ?? "50%"} !important;

    @media (max-width: 576px) {
        width: ${props => props.smWidth ?? "70%"} !important;
    }
`

export const Indicator = styled.ul`
    left: 47%;
    max-height: fit-content;
    border-left: 1px dashed #0693A5;
    position: absolute;

    .indicator-number {
        list-style: none;
        padding-bottom: 27px;
        margin-left: -15px;
        color: #23ADE5;
        border-radius: 50%;
        padding: 5px 14px 5px;
        background: #C4EDFE;
        width: fit-content;
        margin-bottom: 6.5em;
        margin-left: -3.1em;
    }

    @media (max-width: 1200px) {
        left: 50%;
    }

    @media (max-width: 992px) {
        left: 53%;

    }

    @media (max-width: 768px) {
        left: 10%;
    }
`

export const TextIndicator = styled.div`
    @media (max-width: 1200px) {
        margin-left: 2.5em;
        .indicator-text { font-size: 0.85rem; }
    }

    @media (max-width: 992px) {
        margin-left: 5em;
        .indicator-text { font-size: 0.85rem; }
    }

    @media (max-width: 768px) {
        margin-left: 2.5em;
        .indicator-text { font-size: 0.85rem; }
    }
`

export const PrivacyPolicyContentList = styled.div`
    position: fixed;
    left: 17%;
    top: 16.5%;
`

export const PrivacyPolicyContent = styled.div`
    margin-top: -2em;
    border-left: #ccc 1px solid;
    ol li, ul li, ol > div > p  { font-size: 16px; }
    ol li:first-child, div li:first-child { text-transform: capitalize; }
    @media (max-width: 576px) { margin-top: 0; }
    @media (max-width: 1200px) { border-left: none; }
`

export const CheckoutForm = styled.div`
    border-left: 1px solid #ccd;
    @media (max-width: 1200px) { border-left: none }
`