import React from 'react';
import { Col, Row } from "react-bootstrap"
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import { ThumbIconSvg, AuthContainerWrapper, AuthContainer, AuthHeaderSection, AuthImageSection } from "./styled";

type Props = {
    title?: string;
    children?: React.ReactNode;
};

export const AuthBoxedContainer = ({
    title,
    children
}: Props) => {
    return (
        <AuthContainer className="card col-lg-10 col-md-12 mx-auto">
            <Row>
                <AuthImageSection lg={5} className="d-none d-lg-block">
                    <ThumbIconSvg />
                </AuthImageSection>
                <Col lg={7} className="p-5">
                    <div className="py-3 pe-0 pe-lg-5">
                        <h2 className="fw-bold">{title}</h2>
                        {children}
                    </div>
                </Col>
            </Row>
        </AuthContainer>
    )
}