import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import React from 'react';
import { Nav, Container, Navbar } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';

export const NavLink = styled(Nav.Link)(`
    margin: 0 1.5em 0 1.5em;
    color: #000 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
`)

export const BaseWrapper = styled.div`
    background-color: #F2F7FF;
    overflow-x: hidden;
    min-height: 100vh;
`

interface BaseContainerProps {
    image?: React.ReactNode | any;
}

export const BaseContainer = styled(Container)<BaseContainerProps>`
    padding: 5em 1em 5em;
    background-color: #F2F7FF;
    background-image: url(${props => props.image});
    background-size: 700px 500px;
    background-position: top right;
    background-repeat: no-repeat;
    min-height: 100vh;
`

export const FooterNavItemContainer = styled.div(`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0 0.5em 0;
    color: #fff !important;

    a { 
        color: #fff !important;
        margin: 0;
    }

    @media (max-width: 768px) {
        a { font-size: 0.9em; }
        flex-direction: column;
        align-items: flex-start;
    }
`)

interface DividerProps {
    smWidth?: string;
}

export const Divider = styled.div<DividerProps>`
    border: 1px solid #777777;
    @media (max-width: 768px) {
        max-width: ${props => props.smWidth ?? '80%'};
    }


`;

export const CopyrightText = styled.p`
    text-align: center;
    color: #fff;
    padding: 0;
    margin: 0.8em 0 0.8em 0;
    font-size: small;
`;

export const FooterIconWrapper = styled.div`
    a { 
        display: inline-block;
        margin: 0; 
    }
`;

export const BounceAnimation = keyframes`
0%   { transform: scale(1,1)      translateY(0); }
10%  { transform: scale(1.1,.9)   translateY(0); }
30%  { transform: scale(.9,1.1)   translateY(-50px); }
50%  { transform: scale(1.05,.95) translateY(0); }
57%  { transform: scale(1,1)      translateY(-2px); }
64%  { transform: scale(1,1)      translateY(0); }
100% { transform: scale(1,1)      translateY(0); }
`

export const Snackbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 25px;
    position: fixed;
    max-width: fit-content;
    bottom: 1.5%;
    right: 1.5%;
    background: #FFFFFF;
    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.16);
    border-radius: 32px 32px 0px 32px;
    // animation: ${BounceAnimation} 5s infinite;
`;

export const NavbarBrand = styled(Navbar.Brand)`
    @media (max-width: 768px) {
        svg { width: 70px; }
    }
`;

export const RightMenuItems = styled(Nav)`
    @media (max-width: 768px) {
        a { font-size: small }
        flex-direction: initial;
        margin-right: auto!important;
        margin-left: auto!important;
    }
`

export const NavbarToggle = styled(Navbar.Toggle)`
    .navbar-toggler-icon {
        line-height: 0 !important;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4'/%3e%3c/svg%3e") !important
    }

    @media (max-width: 768px) {
        right: -1%;
        position: absolute;
        border: none !important;
    }
`
