import React from "react";
import { ProductCard, CheckoutProductBox } from "./styled";
import { Card } from "react-bootstrap";
import currencyFormatter from "utils/currencyFormatter";

interface ProductProps{
    image: string|undefined;
    name?: string;
    amount?: number|string;
}

export const Product: React.FC<ProductProps> = ({image, name, amount}) => {
    return (
        <ProductCard bottomRadius={(name && amount) ? false : true}>
            <img src={image} className="img-fluid card-img-top" />
            {(name && amount) ?
                <Card.Footer className="px-4">
                    <h4 className="fw-bold">{name}</h4>
                    <h5 style={{ color: "#23ADE5"}}>&#x20A6;{currencyFormatter(amount)}</h5>
                </Card.Footer>
             : <></>}
            
        </ProductCard>
    )
}

interface CheckoutProductCardProps{
    image: string;
    name: string;
    amount: number;
    quantity: number;
    removeProduct?: () => void;
}

export const CheckoutProductCard: React.FC<CheckoutProductCardProps> = ({
    image, 
    name, 
    amount, 
    quantity,
    removeProduct
}) => {
    return (
        <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex align-items-center">
                <CheckoutProductBox>
                    <img src={image} className="img-fluid rounded rounded-3" />
                    <span className="badge bg-danger rounded-pill">{quantity}</span>
                </CheckoutProductBox>
                <div className="ms-3">
                    <div style={{ fontSize: "0.875rem"}}>{name}</div>
                    {removeProduct ? <a onClick={() => removeProduct()} className="text-dark">Remove</a> : ""}
                </div>
            </div>
            <div className="text-theme-primary fw-bold">&#x20A6;{currencyFormatter(amount*quantity)}</div>
        </div>
    )
}