import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums"
import { CardConnectionSection } from "./styled";
import { Card } from "react-bootstrap"
import currencyFormatter from "../../../../utils/currencyFormatter";

type IProps = {
    total?: number|string
}

export const CardConnection = ({
    total
}: IProps) => {
  return (
        <CardColumnsContainer
        colProps={{ lg: 12 }}
        title="Total Connections"
        >
            <CardConnectionSection>
                <Card.Title style ={{ fontSize: "2.2rem" }}>
                    {currencyFormatter(total ?? '0')}
                </Card.Title>
            </CardConnectionSection>
        </CardColumnsContainer>
    );
}