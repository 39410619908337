import { Col, Navbar, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/svgs/dark-icon.svg";
import {
  MainCtnColumn,
  HorizontalSpacer,
  NavButton,
  TopLogoNav,
} from "./styled";

import { SideBarNav } from "../../components/sidebar/sidebar";
import { createContext, useEffect, useState } from "react";
import { ph_photo, useAuth, useLogout } from "../../store/auth/hooks";
import { Navigate, useNavigate } from "react-router-dom";
import { useFetchProfileQuery } from "../../store/user/api";
import { ProfileImage } from "../auth/styled";
import { baseUrl, siteUrl } from "../../utils/requests";
import { OutlinedButton } from "../../components/buttons";
import { validationError } from "../../utils/validations";
import { toast } from "react-toastify";
import { useLogOutMutation } from "../../store/auth/api";

interface NavContext {
  isHidden: boolean;
}
const defaultState = {
  isHidden: true,
};
export const NavToggleContext = createContext<NavContext>(defaultState);

type Props = {
  title?: string;
  children?: React.ReactNode;
  previewBtn?: boolean;
};
export const DashboardContainer = ({ title, children, previewBtn }: Props) => {
  const [isHidden, setIsHidden] = useState(true);
  const { user, isLoading } = useAuth();
  const { data: profile} = useFetchProfileQuery(user?.auth_id);
  const [destroyToken, { isLoading: loadingLogout }] = useLogOutMutation();
  const logout  = useLogout();
  const navigate = useNavigate();


  useEffect(() => {
    if (!user)  navigate("/auth/login");
  }, [isLoading, user, profile]);
  

  const openPreview = () => {
    const url = "/connect/" + profile?.data?.username;
    return window.open(url, '_blank');
  }

  const signOut = () => {
    if (window.confirm("Your are about to logout of your account?")) {
      destroyToken().unwrap().then((res) => {
        toast.success(res?.response ?? "Login to access your account");
        setTimeout(() => logout(), 1500);
      }).catch((error) => {
        // console.log(error);
        toast.error(error?.message ?? validationError(error?.data?.response));
      })
    }
  }

  const availableImage = () => {
    if (profile?.data?.photo?.avatar) return profile?.data?.photo?.avatar;
    return require("../../assets/images/user-default-img.png")
  }

  return (
    <>
      <NavToggleContext.Provider value={{ isHidden }}>
        <Navbar bg="white" className="fixed-top shadow py-0">
          <TopLogoNav className="d-flex justify-content-between align-items-center shadow py-3 w-sm-80 w-auto">
            <Logo width="85" className="ml-6em" />
            <div
              style={{
                background: "#FFFFFF",
                boxShadow: "0px 4px 16px rgba(171, 171, 171, 0.18)",
                padding: "0.6em",
                borderRadius: "50%",
                marginRight: "-1.5em",
              }}
              className="border"
            >
              <NavButton onClick={() => setIsHidden(!isHidden)}>
                <span></span>
                <span></span>
                <span></span>
              </NavButton>
            </div>
          </TopLogoNav>
          <Col className=" ms-5">
            <div className="d-flex justify-content-end justify-content-md-between align-items-center">
              <h5 style={{ color: "#000" }} className={"d-none d-md-block"}>{title}</h5>
              <Navbar.Text className="d-flex justify-content-around me-3 me-md-5 align-items-center">
                {previewBtn && (
                  <OutlinedButton text="Preview" className="btn-sm px-4 py-2" onClick={() => openPreview()}/>
                )}

                <div className="d-inline ms-2 ms-md-4">
                  <OverlayTrigger
                    container={this}
                    trigger="click"
                    placement="bottom"
                    overlay={(
                      <Popover id="popover-positioned-scrolling-right" title="Popover right">
                        <div className="py-2 px-3 touchable">
                          <strong className="text-danger" onClick={() => signOut()}>
                            {loadingLogout ? "Signing out...." : "Sign out"}
                          </strong>
                        </div>
                      </Popover>
                    )}
                  >
                    <ProfileImage src={availableImage()} />
                  </OverlayTrigger>
                </div>
              </Navbar.Text>
            </div>
          </Col>
        </Navbar>
        <div className="container-fluid">
          <Row>
            <SideBarNav />

            <MainCtnColumn
              className={isHidden ? "p-4 ml-17" : "p-4"}
              style={{ paddingTop: "6.7em" }}
            >
              <HorizontalSpacer height={"5rem"} heightSm={"5rem"} />
              {children}
            </MainCtnColumn>
          </Row>
        </div>
      </NavToggleContext.Provider>
    </>
  );
};
