import {  Form, InputGroup } from "react-bootstrap";
import { PrimaryButton } from "../../components/buttons";
import { AuthBoxedContainer } from "../../containers/auth/authBoxedContainer";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { LinkComponent } from "../../components/texts/styled";
import { useState } from "react";
import { FloatingLabelForm } from "../../components/inputs/themeInput";
import { useParams } from "react-router-dom";
import { useResetMutation } from "../../store/auth/api";
import { BaseViewContainer } from "containers/main/baseViewContainer";

export const ResetPasswordPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [confirm, setConfirmPassword] = useState("");
    const [password, setNewPassword] = useState(" ");
    const params = useParams();
    const { token } = params;
    const [resetPassword] = useResetMutation();

    const handleReset = () => {
        console.log(confirm, "confirm password", password, "new password");
        resetPassword({token, password, confirm}).unwrap().then(res => {
            console.log(res);
            if(res.status){
                alert("Password changed successfully");
            }else{
                alert("Password not changed");
            }
        }).catch(err => {
            console.log(err);
            alert("Password not changed");
        })

    }
    

    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <AuthBoxedContainer title={'Reset Password'}>
                <p>Input your new password in here</p>
                <div className="row">
                    <FloatingLabelForm 
                        type="password" 
                        label="New Password" 
                        name="password" 
                        inputBgTransparent={true}
                        placeholder="New Password" 
                        setValue={setNewPassword}
                    />

                    <br/>
                    <FloatingLabelForm 
                        type="password" 
                        label="Confirm Password" 
                        name="password"
                        inputBgTransparent={true}
                        placeholder="Confirm Password"
                        setValue={setConfirmPassword}
                    />
                </div>
                <br/>
                <div onClick={handleReset} className="mt-3 mb-5">
                    <PrimaryButton text={"Submit"} />
                </div>

            </AuthBoxedContainer>
        </BaseViewContainer>
    )
}
