import 'react-phone-number-input/style.css'
import { ThemeInputComponentProp } from './themeInput'
import { ErrorText, FloatingPhoneControl } from './styled'



export const PhoneInputComponent = ({
    type,
    name,
    label,
    placeholder,
    value,
    setValue,
    helperText,
    ...rest
}: ThemeInputComponentProp) => {
    return (
        <div className="mb-3">
            <FloatingPhoneControl
                international
                placeholder={placeholder}
                defaultCountry={"NG"}
                value={value}
                onChange={(val) => setValue && setValue((val as string))}
                style={{
                    border: '1px solid #BDB2B2',
                    borderRadius: '5px',
                    padding: '15px 20px'
                }}
            />
            <ErrorText className="text-danger font-size-sm">{rest.error ? helperText : ""}</ErrorText>
        </div>
    )
}

