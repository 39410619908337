import { OutlinedButton, PrimaryButton } from "../../../components/buttons"
import { FloatingLabelForm } from "../../../components/inputs/themeInput"
import { ReactComponent as WhatsAppSvg } from "../../../assets/svgs/bxl_whatsapp.svg";
import { toast } from "react-toastify";
import { validationError } from '../../../utils/validations';
import { useExchangeContactMutation } from "../../../store/user/api";
import { ExchangeContactPayload, ProfileData } from "../../../store/user/interface";
import { useState } from "react";

type IProps = {
    user: ProfileData,
    onClose?: () => void;
}
export const ExchangeContactsComponent = ({
    user,
    onClose
}: IProps) => {
    const [form, setForm] = useState<ExchangeContactPayload>({ id: user?.auth_id, contact_name: '', contact_phone: '' });
    const [formError, setFormError] = useState({ contact_name: false, contact_phone: false });
    const [exchangeContact, { isLoading }] = useExchangeContactMutation();

    const setValue = (param: keyof ExchangeContactPayload, value: string) => {
        setForm((prev: any) => ({ ...prev, [param]: value }));
        setFormError((prev: any) => ({ ...prev, [param]: false }));
    }

    const messageWhatsapp = () => {
        const message = `Hi ${user?.name},\nI'm ${form?.contact_name}, would love to connect, add my contact (${form?.contact_phone}).`;
        const url = `https://api.whatsapp.com/send?phone=${user?.whatsapp_number}&text=${message}`;
        return url;
    }

    const onSubmit = () => {
        if (!form?.contact_name.length ) {
            setFormError((prev: any) => ({ ...prev, contact_name: true }));
        } else if (!form?.contact_phone.length) {
            setFormError((prev: any) => ({ ...prev, contact_phone: true }));
        } else {
            exchangeContact(form).unwrap().then((data) => {
                toast.success(data?.data?.response ?? "Contact exchanged successfully");
                onClose && onClose();
            }).catch((error) => {
                console.log(error);
                toast.error(error?.message ?? validationError(error?.data?.response));
            });
        }
    }

    return (
        <div>
            <FloatingLabelForm
                label="Full Name"
                value={form.contact_name}
                placeholder="Name"
                setValue={(value) => setValue('contact_name', value)}
                error={formError.contact_name} 
                helperText="Kindly enter your name."
            />

            <FloatingLabelForm
                label="Phone Number"
                value={form.contact_phone}
                placeholder="Phone Number"
                setValue={(value) => setValue('contact_phone', value)}
                error={formError.contact_phone}
                helperText="Kindly enter your phone number."
            />
            <div className="d-flex justify-content-between align-items-center">
                <PrimaryButton text={"Send"}
                    isLoading={isLoading}
                    onClick={onSubmit}
                    style={{
                        flexGrow: 0.6,
                        // maxWidth: "130px",
                        padding: "12px 10px"
                    }}
                />
                &nbsp;&nbsp;&nbsp;
                <a href={messageWhatsapp()} target="_blank" style={{flexGrow: 2, minWidth: "190px",}}>
                    <OutlinedButton
                        style={{
                            borderColor: "#000",
                            padding: "12px 10px"
                        }}
                    >
                        <WhatsAppSvg />&nbsp;&nbsp;
                        <small>Send via Whatsapp</small>
                    </OutlinedButton>
                </a>
            </div>
        </div>

    )
}