import { useEffect, useState } from "react"
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { GalleryAndQR } from "./components/galleryAndQR";
import { Files } from "./components/files";
import { SocialsAndLinks } from "./components/socialsAndLinks";
import { UserProfileSummary } from "./components/userProfileSummary";
import { CompanyProfileAndBank } from './components/companyProfileAndBank';
import { ConnectContainer } from "./components/styled";
import { authBaseUrl, baseUrl } from "../../utils/requests"
import axios from "axios"
import { useLocation } from "react-router-dom"
import { useFetchProfileQuery } from "../../store/user/api";
import { ProfileData } from "../../store/user/interface";

export const ConnectHomePage = () => {
    const { pathname } = useLocation()
    const pathArray: string[] = pathname.trim().split("/")
    const userId = pathArray[pathArray.length - 1]
    const [user, setUser] = useState<ProfileData>();
    const { data, isLoading, error } = useFetchProfileQuery(userId);

    useEffect(() => {
        
        if (data && !data?.data) alert(data?.response);
        if (data?.data) {
            setUser(data?.data);
            document.title = `${data?.data.name} | SmartCard TAP-IT Digital`;
        }
    }, [data]);

    // console.log(data, error);
    
    return (
        (!user || isLoading) ? (
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
                <div>
                    <div className="d-flex justify-content-center align-items-center h-100 mb-3">
                        <Spinner animation="border" />
                    </div>
                    <p className="text-center">Loading information...</p>
                </div>
            </Container>
        ) : (
            <ConnectContainer>

                <Row>
                    <Col xs={12} className="mb-2">
                        <UserProfileSummary user={user} />
                    </Col>
                    <Col xs={12} className="mb-2">
                        <SocialsAndLinks user={user} />
                    </Col>
                    <Col xs={12} className="mb-2">
                        <CompanyProfileAndBank user={user} />
                    </Col>
                    <Col xs={12} className="mb-2">
                        <GalleryAndQR user={user} />
                    </Col>
                    {(user?.file && user?.file.length > 0) ? (
                        <Col xs={12} className="mb-5">
                            <Files user={user} />
                        </Col>
                    ): (<></>)}
                    
                    <Col xs={12}>
                        <div className="text-center">
                            Powered by TAP IT NIGERIA
                        </div>
                    </Col>
                </Row>

            </ConnectContainer>
        )
    );
}