import { Card } from "react-bootstrap"
import React from "react"
import { BoxedInfoTextComponent } from "../../../components/views/boxedInfoText"
import { ConnectCardsContainer } from "../../../containers/connect/connectCards"
import { CardTitle } from "./styled";

export const CompanyProfileAndBank: React.FC<{user: any}> = ({user}) => {
    return (
        <>
            <ConnectCardsContainer>
                <div className="mb-5">
                    <CardTitle className="fw-bold">Company Profile</CardTitle>
                    <div className="mt-3">
                        <BoxedInfoTextComponent title={"Company Name"} value={user?.company?.name ?? 'None'} />
                        <BoxedInfoTextComponent title={"Address"} value={user?.company?.address ?? 'None'} />
                        <BoxedInfoTextComponent title={"Nature of Business"} value={user?.company?.nature ?? 'None'} />
                    </div>
                </div>

                {
                    (user?.company?.bank?.account_no && 
                    user?.company?.bank?.bank_name && 
                    user?.company?.bank?.account_name) ?
                        <div className="mt-3">
                            <CardTitle className="fw-bold mt-5">Company Bank Details</CardTitle>
                            <div className="mt-3">
                                <BoxedInfoTextComponent title={"Bank Name"} value={user?.company?.bank?.bank_name ?? 'None'}/>
                                <BoxedInfoTextComponent title={"Account Number"} value={user?.company?.bank?.account_no ?? 'None'} copy={(user?.company?.bank?.account_no) ? true : false}/>
                                <BoxedInfoTextComponent title={"Account Name"} value={user?.company?.bank?.account_name ?? 'None'} />
                            </div>
                        </div>
                    : ""
                }
            </ConnectCardsContainer>
        </>
    )
}