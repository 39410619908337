// import { notify, notifyInfo } from "./notify"

import { toast } from "react-toastify";
import { IResponse } from "../store/auth/interface";

type ErrorRes = {
    data: string|any,
    message: string
};

export const LogOutErrors = {
    'AUTH_ERROR': '', 'INVALID_DEVICE': '', 'USER_BLOCKED': '', 'Unauthenticated.': ''
};

export const TokenErrors = [
    "token has expired", "token not parsed", "invalid token", "unauthenticated."
]

export const ValidateResponseError = (error: ErrorRes, token: string|null = null) => {
    console.log(error.data, "val_error");
    if (typeof error !== 'string') {
        if (typeof error.data !== 'string') {
            // has meta_data errors
            const hasMetaDataErrors = (error.data && error.data.response);
            let possibleErrors = {
                token: (error?.data.message ? TokenErrors.includes(error?.data.message.trim().toLowerCase()) : false),
                auth: (hasMetaDataErrors ? Object.keys(LogOutErrors).includes(error.data.response) : false)
            };
            // console.log(possibleErrors, error?.data.message.trim().toLowerCase());
            // handle errors 
            if (possibleErrors.token || possibleErrors.auth) {
                if (token) toast.error("Kindly login again to verify your account.");
                return true;
            }
            // continue external validations 
             return false;
        } else {
            // notify("Request Failed", "an error occurred while completing request, kindly try again!");
            console.log("api code break issue");
            return false;
        }
    } else {
        // notify("Request Failed", "kindly check your internet connection, and try again!");
        console.log("might be network issue");
        return false;
    }
}