import { Route, Routes } from 'react-router-dom';
import { HomePage } from '../pages/landing/home';
import { ContactPage } from '../pages/landing/contact';
import { ShipmentPolicyPage } from 'pages/landing/shipmentPolicy';
import { ProductPage } from 'pages/landing/product';
import { CartPage } from 'pages/landing/cart';
import { CheckoutPage } from 'pages/landing/checkout';
import { ViewProductPage } from 'pages/landing/product/view';
import { PrivacyPolicyPage } from 'pages/landing/privacyPolicy';
import { BaseViewWrapper } from 'containers/main/baseViewWrapper'

export const MainRoutes = () => {
    return (
        <Routes>
            <Route element={<BaseViewWrapper />}>
                <Route index element={<HomePage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="shipment-policy" element={<ShipmentPolicyPage />} />
                <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="cart" element={<CartPage />} />
                <Route path="checkout" element={<CheckoutPage />} />
                <Route path="products" element={<ProductPage />} />
                <Route path="products/:id" element={<ViewProductPage />} />
            </Route>
        </Routes>
    );
}