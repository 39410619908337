import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";
import { useState } from "react";
import { FloatingLabelForm, ThemeInputComponentProp,  } from "../../../components/inputs/themeInput";

export const CustomPasswordInput = ({
    ...rest
}: ThemeInputComponentProp) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FloatingLabelForm
            id="outlined-adornment-weight"
            type={showPassword ? "text" : "password"}
            endAdornment={
                // <InputAdornment position="end">
                <div onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </div>
            }
        {...rest}
        />
    )
}