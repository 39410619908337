import { useRef, useState } from "react";
import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums"
import { useUploadCoverPictureMutation } from "../../../../store/user/api";
import { ProfileData } from "../../../../store/user/interface";
import { validationError } from "../../../../utils/validations";
import { ActionSection, IconWrapper, UserCoverPicture } from "../styled";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Spinner } from "react-bootstrap";
import { ReactComponent as Camera } from "../../../../assets/svgs/camera.svg";
import { toast } from "react-toastify";
import { HiddenCropper } from 'react-bootstrap-image-cropper';
import { baseUrl } from "../../../../utils/requests";


type IProps = {
  user?: ProfileData
}

export const EditCoverPhoto = ({
  user
}: IProps) => {
  const triggerRef = useRef<any>();
  const [image, setImage] = useState<{ url: string, file: any }>();
  const [update, { isLoading }] = useUploadCoverPictureMutation();

  const handleCropped = (file: any) => {
    let objectURL = URL.createObjectURL(file);
    setImage({ url: objectURL, file: file });
  }

  const availableImage = () => {
    if (image) return image.url;
    if (user?.photo?.cover) return user?.photo?.cover;
    return require("../../../../assets/images/cover-photo-default.png")
  }

  const initUpload = () => {
    if (image) {
      const filename = "CoverPictureImage.jpg";
      const fileObject = new File([image.file], filename, {
        type: 'image/jpg'
      });
      const payload = new FormData();
      payload.append('cover', fileObject);
      payload.append('auth_id', user?.auth_id ?? '')

      update(payload).unwrap().then((res) => {
        // console.log(res);
        toast.success(res?.response);
        setTimeout(() => setImage(undefined), 2000);
      }).catch((error) => {
        // console.log(error);
        toast.error(error?.message ?? validationError(error?.data?.response));
      });
    }
  }

  
    return (
      <CardColumnsContainer
        colProps={{ lg: 12 }}
        title="Cover Photo"
        subTitle={
          <>
            <span>Tap to upload/change. </span>
            <b>Best Format is in 1074px by 269px</b>
          </>
        }
        classes="pb-3"
      >
        <div className="position-relative">
          <UserCoverPicture
            src={availableImage()}
            alt=""
            className="img-fluid"
          />
          <ActionSection>
            {image && (
              <IconWrapper onClick={() => initUpload()}>
                {(isLoading) ? (<Spinner animation={"border"} size={"sm"} />) : (<CloudUploadOutlinedIcon />)}
              </IconWrapper>
            )}
            &nbsp;&nbsp;
            <IconWrapper onClick={() => triggerRef?.current.trigger()} style={{ width: 'auto', borderRadius: '30px', padding: '10px 25px' }}>
              <Camera width={"20px"} />&nbsp;&nbsp;Edit Cover Photo
            </IconWrapper>
          </ActionSection>
        </div>
        

        <HiddenCropper
          triggerRef={triggerRef}
          onCropped={handleCropped}
          cropOptions={{ aspect: 4 / 1, maxZoom: 10 }}
          // outputOptions={{ maxWidth: 200, maxHeight: 200 }}
          // previewOptions={{ width: 200, height: 200 }}
        />
      </CardColumnsContainer>
    );
}