import { TextTitle, FlexibleContainer, Button} from "./styled"
import { BaseViewContainer } from "containers/main/baseViewContainer";
import PageNotFound from "assets/svgs/page_not_found_re_e9o6.svg";

export const NotFoundPage = () => {
    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <FlexibleContainer style={{ display: "grid", placeContent: "center"}} className="vh-100 text-center">
                <img src={PageNotFound} width="550" className="img-fluid" />
                <TextTitle style={{ color: "#23ADE5"}} className="mt-3">PAGE NOT FOUND</TextTitle>
                <p className="mb-3">The page you are looking for might have been removed had its <br /> name changed or is temporarily unavailable.</p>
                <Button smWidth="50%" lgWidth="30%" href="/" className="mx-auto">HOME PAGE</Button>
            </FlexibleContainer>
        </BaseViewContainer>
    )
}   