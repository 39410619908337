import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums";
import QRCode from "react-qr-code";
import { ProfileData } from "../../../../store/user/interface";
import { siteUrl } from "../../../../utils/requests";

type IProps = {
  user?: ProfileData
}

export const EditQrCode = ({
  user
}: IProps) => {
  return (
    <CardColumnsContainer colProps={{ lg: 12 }} title="QR Code">
      <div className=" d-flex justify-content-center align-items-center pb-4">
        <QRCode
          value={siteUrl + "/connect/" + (user?.username ?? user?.email)}
          size={window.innerWidth > 500 ? undefined : (window.innerWidth - 150)}
        />
      </div>      
    </CardColumnsContainer>
  );
};
