import { ErrorText } from "../inputs/styled";
import {
  BoxedTextContainer,
  BoxedTextTitle,
  BoxedTextValue,
  BoxedInput,
  BoxedInputTitle,
  BoxedInputValue,
} from "./styled";
// import {InputWrapper} from "../../"

type Props = {
  title?: string;
  value?: string;
  placeholder?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>,
  titleProps?: React.HTMLAttributes<HTMLDivElement>,
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  setValue?: (value: string) => void;
};
export const BoxedInfoInputComponent = ({
  title,
  value,
  placeholder,
  containerProps,
  disabled,
  error,
  setValue,
  helperText,
}: Props) => {
  return (
    <div>
      <BoxedTextContainer {...containerProps} error={error}>
        <BoxedInputTitle>
          {title}
        </BoxedInputTitle>
        <BoxedInputValue>
          <BoxedInput
            placeholder={placeholder}
            value={value}
            onChange={({ target }: any) => setValue && setValue(target.value)}
            disabled={disabled}
          />
        </BoxedInputValue>
      </BoxedTextContainer>
      <ErrorText className="text-danger font-size-sm">{error ? helperText : ""}</ErrorText>
    </div>
  );
};
