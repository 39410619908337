import React from "react";
import { OverlayTrigger, Popover, Row } from "react-bootstrap";
import { ThemeInputComponent } from "../../../../components/inputs/themeInput";
import { HexColorPicker } from "react-colorful";

type IProps = {
  header: any;
  value?: string,
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  setValue?: (color: string) => void
}

export const SettingsInputComponent = ({
  header,
  value,
  setValue,
  disabled,
  error,
  helperText,
}: IProps) => {

  return (
    <OverlayTrigger
      container={this}
      trigger="click"
      placement="right"
      overlay={(
        <Popover id="popover-positioned-scrolling-right" title="Popover right">
          <div className="py-2 px-3 touchable">
            <HexColorPicker color={value} onChange={(color) => (setValue && setValue(color))} />
          </div>
        </Popover>
      )}
    >
      <div className="mb-3">
        <label className="fw-bold">{header}</label>
        <br />
        <ThemeInputComponent
          placeholder={"Select Color"}
          value={value}
          disabled={true}
          error={error}
          helperText={helperText}
        />
      </div>
    </OverlayTrigger>
  );
};
