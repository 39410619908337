import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums"
import { BoxedInfoInputComponent } from "../../../../components/views/boxedInfoInput";
import { PrimaryButton } from "../../../../components/buttons"
import { Col, Row } from "react-bootstrap";
import { ProfileData, UpdateCompanyPayload } from "../../../../store/user/interface";
import { useUpdateCompanyMutation } from "../../../../store/user/api";
import { useRef, useState } from "react";
import { validationError } from "../../../../utils/validations";
import { toast } from "react-toastify";
import { HiddenCropper } from 'react-bootstrap-image-cropper';


type IProps = {
  user?: ProfileData
}

export const EditCompanyDetails = ({
  user
}: IProps) => {
  const triggerRef = useRef<any>();
  const [update, { isLoading }] = useUpdateCompanyMutation();
  const [form, setForm] = useState<UpdateCompanyPayload>({
    auth_id: user?.auth_id ?? '',
    name: user?.company?.name ?? '',
    nature: user?.company?.nature ?? '',
    address: user?.company?.address ?? '',
    file: user?.company?.logo ?? '',
    account_name: user?.company?.bank?.account_name ?? '',
    account_no: user?.company?.bank?.account_no ?? '',
    bank_name: user?.company?.bank?.bank_name ?? '',
  });
  const [formError, setFormError] = useState({
    name: false,
    nature: false,
    address: false,
    file: false,
    account_name: false,
    account_no: false,
    bank_name: false,
  });

  const setValue = (param: keyof UpdateCompanyPayload, value: string) => {
    setForm((prev: any) => ({ ...prev, [param]: value }));
    setFormError((prev: any) => ({ ...prev, [param]: false }));
  }

  const handleSubmit = () => {
    if (!form?.name?.length) {
      setFormError((prev: any) => ({ ...prev, name: true }));
    } else if (!form?.nature?.length ) {
      setFormError((prev: any) => ({ ...prev, nature: true }));
    } else if (!form?.address?.length ) {
      setFormError((prev: any) => ({ ...prev, address: true }));
    } else if (form?.account_name?.length && form?.account_name?.length < 3) {
      setFormError((prev: any) => ({ ...prev, account_name: true }));
    } else if (form?.account_no?.length && form?.account_no?.length < 10) {
      setFormError((prev: any) => ({ ...prev, account_no: true }));
    } else if (form?.bank_name?.length && form?.bank_name?.length < 3) {
      setFormError((prev: any) => ({ ...prev, bank_name: true }));
    } else {
      const payload = new FormData();
      Object.keys(form).forEach((param) => {
        const key = (param as keyof UpdateCompanyPayload);
        if (param !== 'file' && form[key] && form[key]?.length) {
          payload.append(param, (form[key] as string | Blob));
        }
      })
      if (form?.file && (user?.company?.logo !== form?.file)){
        const filename = "CompanyPictureImage.jpg";
        const fileObject = new File([form?.file], filename, {type: 'image/jpg'});
        payload.append('file', fileObject);
      }
      // console.log(form, payload);
      update(payload).unwrap().then((res) => {
        toast.success(res?.response);
      }).catch((error) => {
        console.log(error);
        toast.error(error?.message ?? validationError(error?.data?.response));
      })
    }
  }

  return (
    <CardColumnsContainer
      colProps={{ lg: 12 }}
      title="Company Details" subTitle="Please provide your company’s details below"
    >
      <Row>
        <Col md={6}>
          <BoxedInfoInputComponent
            title="Company Name"
            placeholder="ex: RE-BRAND ENTERPRISE"
            value={form?.name}
            setValue={(value) => setValue('name', value)}
            error={formError.name}
            helperText="Company name is required!"
          />
          <BoxedInfoInputComponent
            title="Address"
            value={form?.address}
            placeholder="ex: Plot 909 Klaus Road"
            setValue={(value) => setValue('address', value)}
            error={formError.address}
            helperText="Company address is required!"
          />
          <BoxedInfoInputComponent
            title="Nature"
            value={form?.nature}
            placeholder="ex: Technology"
            setValue={(value) => setValue('nature', value)}
            error={formError.nature}
            helperText="Company type is required!"
          />
          <div onClick={() => triggerRef?.current.trigger()}>
            <BoxedInfoInputComponent
              title="Logo"
              value={(form?.file) ? form?.file : ''}
              placeholder="Upload your logo here"
              disabled={true}
              error={formError.file}
              helperText="Company logo is required!"
              containerProps={{
                style: { borderStyle: "dashed" }
              }}
              titleProps={{
                style: { borderStyle: "dashed !important" }
              }}
            />
          </div>
          
        </Col>
        <Col md={6}>
          <BoxedInfoInputComponent title="Bank Name"
            value={form?.bank_name}
            placeholder="ex: Fidelity Bank"
            setValue={(value) => setValue('bank_name', value)}
            error={formError.bank_name}

            helperText="Bank name is required!"
          />
          <BoxedInfoInputComponent title="Account Number"
            value={form?.account_no}
            placeholder="ex: 0123456789"
            setValue={(value) => setValue('account_no', value)}
            error={formError.account_no}
            helperText="Account number is required!"
          />
          <BoxedInfoInputComponent title="Account Name "
            value={form?.account_name}
            placeholder="ex: George Forge"
            setValue={(value) => setValue('account_name', value)}
            error={formError.account_name}

            helperText="Account number is required!"
          />
        </Col>
        <Col md={12} className="d-flex justify-content-end pb-3">
          <PrimaryButton 
            text={"Update"} 
            style={{ width: '300px' }} 
            isLoading={isLoading}
            onClick={() => handleSubmit()}
            />
        </Col>
      </Row>
      <HiddenCropper
        triggerRef={triggerRef}
        onCropped={(file:any) => setValue('file', file)}
        cropOptions={{ aspect: 1 / 1, maxZoom: 10 }}
        outputOptions={{ maxWidth: 200, maxHeight: 200 }}
        previewOptions={{ width: 200, height: 200 }}
      />
    </CardColumnsContainer>
  )
} 
