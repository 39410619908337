import { useRef, useState } from "react";
import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums"
import { Col, Row, Spinner } from "react-bootstrap";
import { CloseBtn, CloseBtnContainer, GalleryImage, IconWrapper } from "../styled";
import { ProfileData, UpdateFilePayload } from "../../../../store/user/interface";
import { toast } from "react-toastify";
import { validationError } from '../../../../utils/validations';
import { useUploadFileMutation, useDeleteImageMutation } from "../../../../store/user/api";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { baseUrl } from "../../../../utils/requests";
import { ThemeInputComponent } from "components/inputs/themeInput"
import { PrimaryButton } from "components/buttons"
import { BoxedInfoInputComponent } from "components/views/boxedInfoInput";
import { FilePicker } from 'react-file-picker'

type IProps = {
  user?: ProfileData
}

export const EditFiles = ({
    user
}: IProps) => {
    const [update, { isLoading }] = useUploadFileMutation();
    const [form, setForm] = useState<UpdateFilePayload>({
        auth_id: user?.auth_id ?? '',
        title: '', file: ''
    });
    const [formError, setFormError] = useState({title: false, file: false});

    const setValue = (param: keyof UpdateFilePayload, value: string) => {
        setForm((prev: any) => ({ ...prev, [param]: value }));
        setFormError((prev: any) => ({ ...prev, [param]: false }));
    }

    const initUpload = () => {
        if (form?.title.length < 1) {
          setFormError((prev: any) => ({ ...prev, title: true }));
        } else if (form?.file.length < 1) {
            setFormError((prev: any) => ({ ...prev, file: true }));
        } else {
            const payload = new FormData();

            payload.append('file', form?.file);
            payload.append('title', form?.title);
            payload.append('auth_id', user?.auth_id ?? '')

            update(payload).unwrap().then((res) => {toast.success(res?.response)})
            .catch((error) => {toast.error(error?.data?.response)})
            .catch((error) => {toast.error("Error uploading file")})
        }
    }
    
    return (
        <CardColumnsContainer
            colProps={{ lg: 12 }}
            title="Files" 
            subTitle={(<><span>File uploads</span><b> (Format supported: pdf, doc)</b></>)}
        >
            <div className="row">
                <div className="col-md-6" style={{maxHeight: "260px", overflowY: "auto"}}>
                    <h6>Uploaded Files</h6>
                    {(user?.file) ? (
                        ((user?.file).map((res: any) => (
                            <a href={res.file} target="_blank">
                                <BoxedInfoInputComponent
                                    title={res.title}
                                    value={res.file}
                                    disabled={true}
                                    containerProps={{style: { borderStyle: "dashed", borderColor: "#26A8E2" }}}
                                    titleProps={{style: { borderStyle: "dashed !important",  borderColor: "#26A8E2"}}}
                                />
                            </a>
                        )))
                    ) : (<p>No file uploaded yet</p>)}
                </div>

            
                <div className="col-md-6 col-sm-12">
                    <h6>Upload Form</h6>
                    <ThemeInputComponent
                        type="text"
                        placeholder="ex: Your CV, Resume or Company Profile"
                        value={form?.title}
                        setValue={(value) => setValue('title', value)}
                        error={formError.title}
                        helperText="Title is required!."
                    />
                    <FilePicker
                        extensions={['pdf', 'doc']}
                        onChange={(file:any) => setValue('file', file)}
                    >
                        <button className="w-100 m-0 p-0" style={{ border: "none", background: "none"}}>
                            <BoxedInfoInputComponent
                                title="File"
                                value={(form?.file) ? form?.file : ''}
                                placeholder="Upload file (Format supported: pdf, doc)"
                                error={formError.file}
                                disabled={true}
                                helperText="File is required!"
                                containerProps={{style: { borderStyle: "dashed" }}}
                                titleProps={{style: { borderStyle: "dashed !important" }}}
                            />
                        </button>
                    </FilePicker>

                    <PrimaryButton 
                        text={"Upload"} 
                        isLoading={isLoading}
                        onClick={() => initUpload()}
                    />
                </div>
            </div>
        </CardColumnsContainer>
    )
}