import { FormControl, FormHelperText, OutlinedInputProps } from "@mui/material";
import { ErrorText, FloatingFormControl, InputWrapper } from "./styled";

export interface ThemeInputComponentProp extends OutlinedInputProps {
    name?: string;
    label?: string;
    height?: string;
    placeholder?: string;
    inputBgTransparent?: boolean;
    value?: string;
    helperText?: string;
    setValue?: (value: string) => void;
}

export const TextAreaInputFloatingLabel: React.FC<ThemeInputComponentProp> = ({
    name,
    label,
    height = "100px",
    placeholder,
    inputBgTransparent,
    value,
    setValue,
    helperText,
    ...rest
}) => {
    return (
        <div className="mb-3">
            <div className="form-floating">
                <textarea 
                    className={`form-control ${inputBgTransparent ? 'bg-transparent' : ''}`}
                    value={value} onChange={({ target }: any) => setValue && setValue(target.value)}  
                    placeholder={placeholder} id="floatingTextarea" style={{ height: height }}></textarea>
                <label htmlFor="floatingTextarea">{label}</label>
            </div>
            <ErrorText className="text-danger font-size-sm">{rest.error ? helperText : ""}</ErrorText>
        </div>
    )
}