import { ReactNode } from "react";
import { ButtonProps, Dropdown } from "react-bootstrap";
import { OutlinedPrimaryButton, StyledPrimaryButton } from "./styled"

interface Props extends ButtonProps {
    icon?: ReactNode;
    text?: string
    children?: ReactNode;
    isLoading?: boolean;
}
export const PrimaryButton = ({
    icon,
    text,
    children,
    isLoading,
    ...rest
}: Props) => {
    return (
        <StyledPrimaryButton {...rest} disabled={isLoading} className={(isLoading ? "active" : "") + " " + rest.className}>
            {(isLoading) ? (
                <b>Submitting</b>
            ) : (
                (children) ?? (
                    <div className="d-flex align-items-center align-content-center justify-content-center">
                            {icon}{icon ? '  ' + text : text}
                    </div>
                )
            )}
        </StyledPrimaryButton>
    )
}

export const OutlinedButton = ({
    icon,
    text,
    children,
    isLoading,
    ...rest
}: Props) => {
    return (
        <OutlinedPrimaryButton {...rest} className={(isLoading ? "active" : "") + " " + rest.className}>
            {(children) ?? (
                <div className="d-flex align-items-center align-content-center justify-content-center">
                    {icon}{ icon ? '  ' + text : text}
                </div>
            )}
        </OutlinedPrimaryButton>
    )
}

type DropdownButtonProps = {
    text: string
}

export const DropdownButton = ({
    text
}: DropdownButtonProps) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-basic" className="rounded-pill px-5">
                {text}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Export as PDF</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Export as PNG</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}