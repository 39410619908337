import { PrimaryButton } from "../../components/buttons";
import { AuthBoxedContainer } from "../../containers/auth/authBoxedContainer";
import { FloatingLabelForm, ThemeInputComponent } from "../../components/inputs/themeInput";
import { BaseViewContainer } from "containers/main/baseViewContainer";
import { TextAreaInputFloatingLabel } from "components/inputs/textareaInput";

export const ContactPage = () => {
    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <AuthBoxedContainer title={"Contact Us"}>
                <p>We will respond to you within 24 hours. If your issue is urgent, please contact one of our team members directly.</p>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FloatingLabelForm 
                            type="text" 
                            label="First Name" 
                            name="first_name"
                            value={""}
                            inputBgTransparent={true}
                            placeholder="First Name"
                            error={false}
                            helperText="Kindly enter your first name"
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FloatingLabelForm 
                            type="text" 
                            label="Last Name" 
                            name="last_name"
                            value={""}
                            inputBgTransparent={true}
                            placeholder="Last Name"
                            error={false}
                            helperText="Kindly enter your last name"
                        />
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <FloatingLabelForm 
                            type="email" 
                            label="Email Address" 
                            name="email"
                            value={""}
                            inputBgTransparent={true}
                            placeholder="Email Address"
                            error={false}
                            helperText="Kindly enter a valid email address"
                        />
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <FloatingLabelForm 
                            type="text" 
                            label="Phone Number (Optional)" 
                            name="phone"
                            value={""}
                            inputBgTransparent={true}
                            placeholder="Phone Number (Optional)"
                            error={false}
                        />
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <FloatingLabelForm 
                            type="text" 
                            label="Subject" 
                            name="subject"
                            value={""}
                            inputBgTransparent={true}
                            placeholder="Subject"
                            error={false}
                            helperText="Kindly enter the subject of the message"
                        />
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <TextAreaInputFloatingLabel 
                            label="Message" 
                            name="message"
                            value={""}
                            inputBgTransparent={true}
                            placeholder="Subject"
                            error={false}
                            helperText="Kindly enter the your message"
                        />
                    </div>
                    <PrimaryButton text={"Submit"} />
                </div>
            </AuthBoxedContainer>
        </BaseViewContainer>
    )
}