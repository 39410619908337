import { ReactNode } from "react"
import { Card } from "react-bootstrap"
import { ConnectCard } from "./styled"

type Props = {
    children?: ReactNode
}
export const ConnectCardsContainer = ({
    children
}: Props) => {
    return (
        <ConnectCard >
            <Card.Body className="px-3 py-4 p-lg-5">
                {children}
            </Card.Body>
        </ConnectCard>
    )
}