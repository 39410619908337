import styled from "@emotion/styled";
import { Button, Dropdown } from "react-bootstrap";

export const StyledPrimaryButton = styled(Button)(({theme})=> (`
  padding: 17px;
  border: 1px solid ${theme.colors.btn_pb};
  background: ${theme.colors.btn_pbg};
  border-radius: 40px;
  width: 100%;
  color: black;
  font-weight: 500;
  :hover, .active{
    background: ${theme.colors.btn_hpbg} !important;
    color: ${theme.colors.btn_hpt} !important;
  }
`));

export const OutlinedPrimaryButton = styled(StyledPrimaryButton)(({ theme }) => (`
  background:  ${theme.colors.btn_sbg};
  color:  ${theme.colors.btn_st};
  border: 1px solid  ${theme.colors.btn_sb};
  :hover, .active{
    background:  ${theme.colors.btn_hsbg} !important;
  }
`));

export const StyledPrimaryDropdownButton = styled(Button)(({ theme }) => (`
  padding: 10px;
  border: 1px solid ${theme.colors.btn_pb};
  background: ${theme.colors.btn_pbg};
  border-radius: 40px;
  width: 100%;
  color: ${theme.colors.btn_pt};
`));