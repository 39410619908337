import styled from "@emotion/styled";

export const GalleryImage = styled.img(`
    width: 100%;
    max-width: 190px;
`)

export const CloseBtn = styled.button(`
    height: 30px;
    width: 30px;
    padding: 0px;
    border-radius: 50%;
`)

export const CloseBtnContainer = styled.div(`
    width: 100%;
    max-width: 190px;
    min-height: 190px;
    padding: 10px;
    justify-content: flex-end!important;
    position: absolute!important;
    display: flex!important;
    opacity: 0;
    :hover {
        opacity: 1 !important;
    }
`)

export const ActionSection =styled.div(`
    position: absolute;
    right: 30px;
    top: 77%;
    display: flex;
`);

export const IconWrapper = styled.div`
    background: #23ADE5;
    border-radius: 50%;
    display: flex;
    width: 40px;
    cursor: pointer;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    shadow: 0px 3px 4px #eee;
`;

export const UserProfilePicture = styled.img(`
    border-radius: 20px;
    min-height: 245px;
    width: 100%;
    object-fit: cover;
`)

export const UserCoverPicture = styled.img(`
    border-radius: 20px;
    width: 100%;
    height: 210px;
    background-color: #eee;
    object-fit: cover;
`)