import { Col, Row } from "react-bootstrap";
import { LoadingViewContainer } from "../../../components/views/loadingView";
import { DashboardContainer } from "../../../containers/dashboard/dashboardContainer";
import { useAuth } from "../../../store/auth/hooks";
import { useFetchProfileQuery } from "../../../store/user/api";
import { EditCompanyDetails } from "./components/editCompanyDetails";
import { EditCoverPhoto } from "./components/editCoverPhoto";
import { EditGallery } from "./components/editGallery";
import { EditFiles } from "./components/editFiles";
import { EditProfileDetails } from "./components/editProfileDetails";
import { EditProfilePicture } from "./components/editProfilePicture";
import { EditQrCode } from "./components/editQRCode";
import { EditSocialLinks } from "./components/editSocialLinks";
import { ProfileResetPassword } from "./components/resetPassword";

export const EditProfilePage = () => {
  const { user, token } = useAuth();
  const { data: profile, isLoading } = useFetchProfileQuery(user?.auth_id);

  return (
    <DashboardContainer title="Manage Profile" previewBtn={true}>
      {((!user && !profile) || isLoading) ? (
        <LoadingViewContainer />
      ) : (
        <Row className="py-3">
          <Col lg={3}>
            <Row>
              <EditProfilePicture user={profile?.data} />

              <EditProfileDetails user={profile?.data} access_token={token} />

              <ProfileResetPassword />

              <EditQrCode user={profile?.data} />
            </Row>
          </Col>
          <Col lg={9}>
            <Row>
              <EditCoverPhoto user={profile?.data} />

              <EditSocialLinks user={profile?.data} />

              <EditCompanyDetails user={profile?.data} />

              <EditGallery user={profile?.data} />
              
            </Row>
          </Col>
          <Col lg={12}><EditFiles user={profile?.data} /></Col>

        </Row>
      )}
    </DashboardContainer>
  );
};
