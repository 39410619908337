import React from 'react';
import { Container } from 'react-bootstrap';
import { FooterNavItemContainer, Divider, CopyrightText, NavLink, FooterIconWrapper } from '../styled';
import { ReactComponent as Logo } from "assets/svgs/logo.svg";
import { ReactComponent as WhatsApp } from "assets/svgs/whatsapp-light.svg";
import { ReactComponent as Instagram } from "assets/svgs/instagram-light.svg";
import { ReactComponent as Facebook } from "assets/svgs/facebook-light.svg";

export const NavFooter = () => {
    return (
        <footer className="py-1" style={{ backgroundColor: "#2D2F32"}}>
            <Container style={{ maxWidth: "85%"}}>
                <FooterNavItemContainer>
                    <NavLink href="/" className="mt-md-0 mt-5"><Logo width={115} /></NavLink>
                    <NavLink className="mt-md-0 mt-5" href="/privacy-policy">Privacy Policy</NavLink>
                    <NavLink href="/shipment-policy">Shipment Policy</NavLink>
                    <NavLink href="/contact">Contact us</NavLink>
                    <div className="d-flex align-items-center my-md-0 my-5">
                        <NavLink disabled={true}>Follow us</NavLink>
                        <FooterIconWrapper>
                            <NavLink className="mx-2"><WhatsApp /></NavLink>
                            <NavLink className="mx-2"><Instagram /></NavLink>
                            <NavLink className="mx-2"><Facebook /></NavLink>
                        </FooterIconWrapper>
                    </div>
                </FooterNavItemContainer>
                <Divider className="mx-auto" />
                <CopyrightText>Copyright &copy; 2022 - {new Date().getFullYear()} Tapit Digital</CopyrightText>
            </Container>
        </footer>
    )
}