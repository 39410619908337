import React from 'react';
import LogoWaterMark from "assets/images/tapit-logo-watermark.png";
import { BaseContainer } from './styled';

interface Properties {
    children: React.ReactNode;
    backgroundWaterMark?: boolean;
};

export const BaseViewContainer: React.FC<Properties> = ({children, backgroundWaterMark}) => {
    return <BaseContainer fluid image={backgroundWaterMark ? LogoWaterMark : ""}>{children}</BaseContainer>
}