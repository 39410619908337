import { TextTitle, FlexibleContainer} from "./styled"
import { BaseViewContainer } from "containers/main/baseViewContainer";

export const ShipmentPolicyPage = () => {
    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <FlexibleContainer alignItems={false} maxWidth="70%" margin="3em auto 3em auto">
                <TextTitle className="text-center mb-lg-5 mb-3">Shipment Policy</TextTitle>
                <ul>
                    <li>The delivery for orders placed before 2pm takes a maximum of two (2) days for Lagos residents and 5 days for outside Lagos Order placement after 2pm is +1 day added to the delivery timelines.</li>
                    <li>Orders placed on Saturday will be delivered the following week.</li>
                    <li>Shipping fee is free for cities within Lagos. However, orders made outside lagos state attracts a shipping fee.</li>
                    <li>The cost of shipping for outside Lagos state is NGN 1,000.</li>
                    <li>Goods cannot be returned when received.</li>
                    <li>Delivery timelines are Monday - Saturday.</li>
                    <li>Delivery is only limited to within Nigeria.</li>
                </ul>
            </FlexibleContainer>
        </BaseViewContainer>
    )
}