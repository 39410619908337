import { useState } from "react"
import { toast } from "react-toastify"
import { PrimaryButton } from "../../../../components/buttons"
import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums"
import { useUpdatePasswordMutation } from "../../../../store/user/api"
import { UpdatePasswordPayload } from "../../../../store/user/interface"
import { validationError } from '../../../../utils/validations';
import { CustomPasswordInput } from "../../../auth/components/passwordInput"
import { ProfileData } from "../../../../store/user/interface";

type IProps = {
    user?: ProfileData
}

export const ProfileResetPassword = ({
    user
}: IProps) => {
    const [updateProfile, { isLoading }] = useUpdatePasswordMutation()
    const [form, setForm] = useState<UpdatePasswordPayload>({
        auth_id: user?.auth_id ?? '',
        old: '', password: '', confirm: '',
    });
    const [formError, setFormError] = useState({
        old: false, password: false, confirm: false,
    });

    const setValue = (param: keyof UpdatePasswordPayload, value: string) => {
        setForm((prev: any) => ({ ...prev, [param]: value }));
        setFormError((prev: any) => ({ ...prev, [param]: false }));
    }

    const handleSubmit = () => {
        if (!form?.old.length) {
            setFormError((prev: any) => ({ ...prev, old: true }));
        } else if (!form?.password.length) {
            setFormError((prev: any) => ({ ...prev, mobile_number: true }));
        } else if (!form?.confirm.length) {
            setFormError((prev: any) => ({ ...prev, position: true }));
        } else {
            updateProfile(form).unwrap().then((res) => {
                toast.success(res?.response);
                setForm({auth_id: user?.auth_id ?? '', old: '', password: '', confirm: ''});
            }).catch((error) => {
                console.log(error);
                toast.error(error?.message ?? validationError(error?.data?.response));
            })
        }
    }
    return (
        <CardColumnsContainer
            colProps={{ lg: 12 }}
            title="Reset Password" subTitle="Use the below field to reset password"
        >
            <div className="mb-3">
                <CustomPasswordInput
                    placeholder="Old Password"
                    value={form.old}
                    setValue={(value) => setValue('old', value)}
                    error={formError.old}
                    helperText="Old Password is required!."
                />
            </div>
            <div className="mb-3">
                <CustomPasswordInput
                    placeholder="New Password"
                    value={form.password}
                    setValue={(value) => setValue('password', value)}
                    error={formError.password}
                    helperText="Password is required!."
                />
            </div>
            <div className="mb-3">
                <CustomPasswordInput
                    placeholder="Confirm Password"
                    value={form.confirm}
                    setValue={(value) => setValue('confirm', value)}
                    error={formError.confirm}
                    helperText="Confirm Password is required!."
                />
            </div>
            <div className="mb-2">
                <PrimaryButton
                    text={"Submit"}
                    isLoading={isLoading}
                    onClick={() => handleSubmit()}
                />
            </div>
        </CardColumnsContainer>
    )
}