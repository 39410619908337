import styled from "@emotion/styled";
import { Col, Card } from "react-bootstrap"
import { Link } from "react-router-dom";

export const ConnectContainer = styled.div(`
    padding: 5rem 15em;
    background: #EBEBEB;
    min-height: 100vh;

    @media (max-width: 1200px) {
        padding: 5rem 6em;
    }

    @media (max-width: 992px) {
        padding: 5rem 3em;
    }
    @media (max-width: 576px) {
        padding: 5rem 1em;
    }
`)

export const ConnectProfileSection = styled.div(`
    // margin-top: -70px;
`)

export const ConnectCoverSection = styled.div<{ background: string }>(({background}) => (`
    background: ${(background) ? "url(" + background + ")" : "#EBEBEB"};
    background-size: cover !important;
    background-position: top-center !important;
    border-radius: 10px 10px 0px 0px;
    min-height: 230px;

    @media (max-width: 768px) {
        border-radius: unset;
        left: 0;
        top: 0;
        right: 0;
        position: absolute;
        min-height: 107px;
        background-size: contain !important;
    }

`))

export const UserProfilePicture = styled.img(`
    border-radius: 20px;
    height: 210px;
    width: 210px;
    object-fit: cover;
    margin-top: -110px;

    @media (max-width: 768px) {
        height: 160px;
        width: 160px;
        border-radius: 10px 0 0 10px;
        margin-top: 0;
    }

`)

export const FlexContentAround = styled.div`
    display: flex;
    justify-content: around;
    align-items: center;
`;

export const FlexContentBetween = styled.div`
    display: flex;
    justify-content: between;
    align-items: center;
`;

export const SocialIconResizer = styled.a<{disabled?: boolean}>(({ disabled}) => (`
    svg {
        width: 48.9px;
        opacity: ${disabled ? 0.5 : 1};
    }
    
    @media (max-width: 768px) {
        svg {
            width: 35px;
        }
    }
`));

export const CardTitle = styled(Card.Title)(`
    font-size: 28px;

    @media (max-width: 576px) {
        font-size: 20px;
    }
`);

export const CompanyLogo = styled.img(`
    border-radius: 4px;
    height: 32px;
    width: 32px;
    object-fit: contain;

    @media (max-width: 576px) {
        width: 24px;
        height: 24px;
    }
`)

export const ConnectButtonContainer = styled.div(`
    left: 28%;
    position: inherit;

    @media (max-width: 1399px) {
        left: 35%;
    }
`);

export const HeadLine = styled.div`
    width: 92px;
    height: 5px;
    background: #D9D9D9;
    border-radius: 8px;
    margin-bottom: 0.5em;
    margin-right: auto!important;
    margin-left: auto!important;
`;