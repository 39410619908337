import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";
import { store } from "../../store";
import {  setCredential } from "../../store/auth";
import { ValidateResponseError } from "../errorValidations";
import { Auth } from "../../store/auth/interface";


export const initInterceptors = () => {

    axios.interceptors.request.use(
        async (config) => {
            const result = reactLocalStorage.get('@tap_it_user');
            const token = result ? (JSON.parse(result) as Auth).access_token : null;
            // console.log(token, "the auth tokenb")

            if (token) {
                if (config['headers']){
                    config['headers']['Authorization'] = `Bearer ${token}`;
                }else{
                    config.headers = {
                        'Authorization': `Bearer ${token}`
                    }
                }
            }

            config.headers = { ...config.headers };
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const result = reactLocalStorage.get('@tap_it_user');
            const token = result ? (JSON.parse(result) as Auth).access_token : null;
            if (ValidateResponseError(error.response, token)) {
                store.dispatch(setCredential({}));
            }

            return Promise.reject(error);
        }
    );
}