import React, { useState, useEffect } from "react"
import { FlexibleContainer, CheckoutForm } from "./styled"
import { BaseViewContainer } from "containers/main/baseViewContainer";
import { ReactComponent as ArrowCircleLeft } from "assets/svgs/arrow-circle-left.svg";
import { FloatingLabelForm, SelectFloatingLabelForm } from "components/inputs/themeInput";
import { PrimaryButton } from "components/buttons";
import { useLocation, useParams } from "react-router";
import NaijaStates from 'naija-state-local-government';
import { Divider } from "components/views/styled";
import { CheckoutProductCard } from "./components/card";
import { OrderPayload } from "store/product/interface";
import city from "assets/jsons/state.cities.json";
import { isValidPhoneNumber } from "react-phone-number-input";
import { validateEmail, validationError } from 'utils/validations';
import { useOrderMutation } from "store/product/api";
import { usePaystackPayment } from 'react-paystack'
import { toast } from "react-toastify";
import { CartProductData } from "store/product/cart";
import currencyFormatter from "utils/currencyFormatter";
import { SHIPPING_FEE } from "utils/constants";

export const CheckoutPage = () => {
    const params = useParams();
    const cities: any = city;
    const location = useLocation();
    const [cityOptions, setCity] = useState<Array<string>>([]);
    const [shippingFee, setShippingFee] = useState<number>(1000);
    const [items, setCartItems] = useState<CartProductData[]>(location.state.items ?? []);
    const [orderProduct, { isLoading }] = useOrderMutation()
    const [form, setForm] = useState<OrderPayload>({ first_name: '', last_name: '', email: '', address: '', phone: '', city: '', state: 'Lagos', country: 'Nigeria', order: [], transaction_ref: '' });
    const [formError, setFormError] = useState({ first_name: false, last_name: false, email: false, address: false, phone: false, city: false, state: false, country: false });
    const cartTotal = items.reduce((total, item) => (total + (item.quantity * item.amount)), 0);
    const grandTotal = (cartTotal + shippingFee);

    const setValue = (param: keyof OrderPayload, value: string) => {
        setForm((prev: any) => ({ ...prev, [param]: value }));
        setFormError((prev: any) => ({ ...prev, [param]: false }));
    }

    useEffect(() => {
        if (form.state.length) setCity(cities[form?.state as typeof cities]);
        setShippingFee((form.state === "Lagos") ? 1000 : SHIPPING_FEE);

        form.order = items.map((data) => {
            const order: any = [];
            order["product_id"] = data.guid;
            order["quantity"] = data.quantity;
            return order;
        })
    }, [form]);

    const initializePayment = usePaystackPayment({
        email: form.email, amount: (grandTotal) * 100,
        publicKey: 'pk_live_d4399e6ea32a42fcc6da037f356133871e9e3053',
    })

    const onSuccess = (response: any) => {
        form.transaction_ref = response.reference;
        orderProduct(form).unwrap().then((data) => toast.success(data?.response))
        .catch((error) => toast.error("Something went wrong, please try again"))
    };

    const submit = () => {
        if (form?.first_name.length < 1) {
            setFormError((prev: any) => ({ ...prev, first_name: true }));
        } else if (form?.last_name.length < 1) {
            setFormError((prev: any) => ({ ...prev, first_name: true }));
        } else if (form?.email.length < 1 || !validateEmail(form?.email)) {
            setFormError((prev: any) => ({ ...prev, email: true }));
        } else if (form?.phone.length < 1) {
            setFormError((prev: any) => ({ ...prev, phone: true }));
        } else if (form?.state.length < 1) {
            setFormError((prev: any) => ({ ...prev, state: true }));
        } else if (form?.city.length < 1) {
            setFormError((prev: any) => ({ ...prev, city: true }));
        } else if (form?.address.length < 1) {
            setFormError((prev: any) => ({ ...prev, address: true }));
        } else {
            initializePayment(onSuccess)
        }
    }

    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <FlexibleContainer alignItems={false} className="row g-5" margin="1em 1em 1em 6.5em">
                <a href="/products" className="mb-md-1 mb-4"><ArrowCircleLeft /> Back</a>
                <div className="col-xl-5 col-lg-12 col-sm-12">
                    {(items).map((item) => (
                        <CheckoutProductCard
                            quantity={item.quantity}
                            amount={item.amount}
                            image={item.image}
                            name={item.name}
                        />
                    ))}

                    <Divider />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <div>Subtotal <br /> Shipping Fee</div><br />
                        <div className="text-theme-primary">
                            &#x20A6;{currencyFormatter(cartTotal)} <br />  &#x20A6;{shippingFee}
                        </div>
                    </div>
                    <Divider />
                    <div className="d-flex justify-content-between align-items-center my-4">
                        <div style={{ fontWeight: "500" }}>Grand Total</div>
                        <div className="text-theme-primary"> &#x20A6;{currencyFormatter(grandTotal)} </div>
                    </div>
                </div>
                <CheckoutForm className="col-xl-7 col-lg-12 col-sm-12">
                    <div className="row">
                        <h5>Contact Information</h5>
                        <div className="col-md-6 col-sm-12">
                            <FloatingLabelForm
                                type="text"
                                label="First Name"
                                name="first_name"
                                value={form.first_name}
                                setValue={(value) => setValue('first_name', value)}
                                inputBgTransparent={true}
                                placeholder="ex: Doe"
                                error={formError.first_name}
                                helperText="Kindly enter your first name"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <FloatingLabelForm
                                type="text"
                                label="Last Name"
                                name="last_name"
                                value={form.last_name}
                                setValue={(value) => setValue('last_name', value)}
                                inputBgTransparent={true}
                                placeholder="ex: Doe"
                                error={formError.last_name}
                                helperText="Kindly enter your last name"
                            />
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <FloatingLabelForm
                                type="email"
                                label="Email Address"
                                name="email"
                                value={form.email}
                                setValue={(value) => setValue('email', value)}
                                inputBgTransparent={true}
                                placeholder="ex: someone@example.com"
                                error={formError.email}
                                helperText="Kindly enter a valid email"
                            />
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <FloatingLabelForm
                                type="text"
                                label="Phone Number"
                                name="phone"
                                value={form.phone}
                                setValue={(value) => setValue('phone', value)}
                                inputBgTransparent={true}
                                placeholder="ex: 09083787249"
                                error={formError.phone}
                                helperText="Kindly enter your phone number"
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <h5>Shipping Address</h5>
                        <div className="col-md-12 col-sm-12">
                            <FloatingLabelForm
                                type="text"
                                label="Country"
                                name="country"
                                value={"Nigeria"}
                                readOnly={true}
                                setValue={(value) => setValue('country', value)}
                                inputBgTransparent={true}
                                placeholder="ex: Nigeria"
                                error={formError.country}
                                helperText="Kindly enter your country"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <SelectFloatingLabelForm
                                label="State"
                                name="state"
                                value={form.state}
                                placeholder={"Enter your state"}
                                inputBgTransparent={true}
                                setValue={(value) => setValue('state', value)}
                                error={formError.state}
                                options={NaijaStates.states()}
                                helperText="Enter your state"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <SelectFloatingLabelForm
                                label="City"
                                name="city"
                                value={form.city}
                                placeholder={"Enter your city"}
                                inputBgTransparent={true}
                                setValue={(value) => setValue('city', value)}
                                error={formError.city}
                                options={cityOptions}
                                helperText="Enter your city"
                            />
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <FloatingLabelForm
                                type="text"
                                label="Street Address"
                                name="address"
                                value={form.address}
                                setValue={(value) => setValue('address', value)}
                                inputBgTransparent={true}
                                placeholder="Street Address"
                                error={formError.address}
                                helperText="Kindly enter your street address"
                            />
                        </div>
                    </div>
                    <PrimaryButton text={"Proceed to Payment"} onClick={submit} isLoading={isLoading} />
                </CheckoutForm>
            </FlexibleContainer>
        </BaseViewContainer>
    )
}