import { BoxedTextContainer, BoxedTextTitle, BoxedTextValue } from "./styled"
import { ReactComponent as CopySVG } from "../../assets/svgs/copy.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';


type Props = {
    title?: string,
    value?: string;
    copy?: boolean;
}
export const BoxedInfoTextComponent = ({
    title,
    value,
    copy
}: Props) => {
    return (
        <BoxedTextContainer>
            <BoxedTextTitle>
                {title}
            </BoxedTextTitle>
            <BoxedTextValue>
                <div>{value}</div>
                {(copy) && (
                    <CopyToClipboard text={value}
                        onCopy={() => alert(`${title} copied.`)}>
                        <CopySVG />
                    </CopyToClipboard>
                )}
            </BoxedTextValue>
        </BoxedTextContainer>
    )
} 