import styled from '@emotion/styled';
import { Card } from "react-bootstrap";

interface ProductCardProps {
    bottomRadius?: boolean;
}

export const ProductCard = styled(Card)<ProductCardProps>(({
    bottomRadius
}) => (`
    img {
        text-center: center;
        margin-left: auto;
        margin-right: auto;
        transition: transform .2s;
    }
    background: transparent !important;
    border-radius: 32px 32px 32px 32px;

    .card-footer {
        background: transparent !important;
    }

    .card-img-top {
        border-top-left-radius: 32px !important;
        border-top-right-radius: 32px !important;
        border-bottom-left-radius: ${bottomRadius ? "32px" : 0} !important;
        border-bottom-right-radius: ${bottomRadius ? "32px" : 0} !important;
    }
`
))

export const CheckoutProductBox = styled.div`
    display: flex;
    justify-content: between;
    align-items: center;
    width: 70px;
    height: 66.33px;
    border: 0.778842px solid #E5EBF5;
    border-radius: 3.81633px;
    position: relative;

    .badge {
        position: absolute;
        margin: -5em 0 0 5em;
    }
`