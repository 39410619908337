import ThumbIcon from "../../assets/svgs/touch.svg";
import {
  SideBarColumn,
  SideBarLinkContainer,
  SideBarLinks,
} from "../../containers/dashboard/styled";
import {  Nav } from "react-bootstrap";
import { ReactComponent as UserLineSvg } from "../../assets/svgs/clarity_user-line.svg";
import CircularConnectionSvg from "../../assets/svgs/circular-connection.svg";
import CircularConnectionSvgAlt from "../../assets/svgs/circular-connection-alt.svg";
import QRCodeSvg from "../../assets/svgs/qr-code-light.svg";
import QRCodeSvgAlt from "../../assets/svgs/qr-code-light-alt.svg";
import SettingsSvg from "../../assets/svgs/settings.svg";
import SignOutSvg from "../../assets/svgs/logout.svg";
import SettingAlt from "../../assets/svgs/settings-alt.svg";
import StrokeUser from "../../assets/svgs/stroke-white-users.svg";
import StrokeUserAlt from "../../assets/svgs/stroke-white-users-alt.svg";
import { NavToggleContext } from "../../containers/dashboard/dashboardContainer";
import { useContext } from "react";
import { useLogout } from "../../store/auth/hooks";
import { toast } from "react-toastify";
import { useLogOutMutation } from "../../store/auth/api";
import { validationError } from '../../utils/validations';

type Props = {
  title?: string;
  url?: string;
  iconActive?: React.ReactNode;
  icon?: string;
  className?: string;
};

const SideBarUnit = ({
  title,
  url,
  icon,
  iconActive,
  className,
  ...restProps
}: Props) => {
  return (
    <>
      <SideBarLinks
        to={`/dashboard/${url}`}
        icon={icon}
        iconactive={iconActive}
        className={className}
        {...restProps}
      >
        <div className="icon"></div>&nbsp;&nbsp;{title}
      </SideBarLinks>
    </>
  );
};
export const SideBarNav = () => {
  const { isHidden } = useContext(NavToggleContext);
  const [destroyToken, { isLoading }] = useLogOutMutation();
  const logout = useLogout();
  
  const signOut = () => {
    if (window.confirm("Your are about to logout of your account?")) {
      destroyToken().unwrap().then((res) => {
        toast.success(res?.response ?? "Login to access your account");
        setTimeout(() => logout(), 1500);
      }).catch((error) => {
        // console.log(error);
        toast.error(error?.message ?? validationError(error?.data?.response));
      })
    }
  }

  return (
    <>
      {isHidden ? (
        <SideBarColumn style={{ background: `url(${ThumbIcon}),#090C11` }}>
          <div className="d-flex flex-column h-100">
            <SideBarLinkContainer>
              <Nav
                variant="pills"
                defaultActiveKey="/edit-profile"
                className="flex-column px-1"
              >
                <SideBarUnit
                  title={"Manage Profile"}
                  url={"edit-profile"}
                  icon={StrokeUser}
                  iconActive={StrokeUserAlt}
                />
                <SideBarUnit
                  title={"Your Connections"}
                  url={"connections"}
                  icon={CircularConnectionSvg}
                  iconActive={CircularConnectionSvgAlt}
                />
                <SideBarUnit
                  title={"QR Code"}
                  url={"qrcode"}
                  icon={QRCodeSvg}
                  iconActive={QRCodeSvgAlt}
                />
                {/* <SideBarUnit
                  title={"Manage Users"}
                  url={"company/users"}
                  icon={StrokeDoubleUser}
                  iconActive={StrokeDoubleUserAlt}
                /> */}
                
                <SideBarUnit
                  title={"Settings"}
                  url={"settings"}
                  icon={SettingsSvg}
                  iconActive={SettingAlt}
                />
                <SideBarLinks
                  to={`/`}
                  icon={SignOutSvg}
                  iconactive={SignOutSvg}
                  className={"mt-5"}
                  onClick={() => signOut()}
                >
                  {isLoading ? "Signing out...." : "Logout"}&nbsp;&nbsp;<div className="icon"></div>
                </SideBarLinks>
              </Nav>
            </SideBarLinkContainer>
            {/* <div className="fixed-bottom" style= {{ width: "fit-content", marginLeft: "-33px", marginBottom: "-60px"}}>
                        <ThumbIcon class="img-fluid" style={{ width: "72%"}} />
                    </div> */}
          </div>
        </SideBarColumn>
      ) : (
        ""
      )}
    </>
  );
};
