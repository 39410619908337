import { FormControl, FormHelperText, OutlinedInputProps } from "@mui/material";
import { ErrorText, FloatingFormControl, InputWrapper } from "./styled";

export interface ThemeInputComponentProp extends OutlinedInputProps {
    type?: string;
    name?: string;
    label?: string;
    placeholder?: string;
    inputBgTransparent?: boolean;
    value?: string;
    helperText?: string;
    setValue?: (value: string) => void;
}

export const FloatingLabelForm: React.FC<ThemeInputComponentProp> = ({
    type,
    name,
    label,
    placeholder,
    inputBgTransparent,
    value,
    setValue,
    helperText,
    ...rest
}) => {
    return (
        <div className="mb-3">
            <FloatingFormControl>
                {rest.startAdornment && (
                    <div className="floating-form-append">{rest.startAdornment}</div>
                )}
                <div className="form-floating flex-grow-1">
                    <input
                        type={type}
                        className={`form-control ${inputBgTransparent ? 'bg-transparent' : ''}`}
                        value={value}
                        // id="floatingInput" 
                        placeholder={placeholder}
                        onChange={({ target }: any) => setValue && setValue(target.value)}
                    // {...rest}
                    />
                    <label htmlFor="floatingInput">{label ?? placeholder}</label>
                </div>
                {rest.endAdornment && (
                    <div className="floating-form-append">{rest.endAdornment}</div>
                )}
            </FloatingFormControl>
            <ErrorText className="text-danger font-size-sm">{rest.error ? helperText : ""}</ErrorText>
        </div>
        
    )
}

export interface ThemeSelectComponentProp extends OutlinedInputProps {
    name?: string;
    label?: string;
    value?: string;
    inputBgTransparent?: boolean;
    helperText?: string;
    options: Array<string>;
    placeholder?: string;
    setValue?: (value: string) => void;
}

export const SelectFloatingLabelForm: React.FC<ThemeSelectComponentProp> = ({
    type,
    name,
    label,
    value,
    inputBgTransparent,
    placeholder,
    options,
    setValue,
    helperText,
    ...rest
}) => {
    return (
        <div className="mb-3">
            <FloatingFormControl>
                {rest.startAdornment && (
                    <div className="floating-form-append">{rest.startAdornment}</div>
                )}
                <div className="form-floating flex-grow-1">
                    <select 
                        value={value} id="floatingSelect" aria-label="Floating label select example"
                        onChange={({ target }: any) => setValue && setValue(target.value)} 
                        name={name} className={`form-select border-0 ${inputBgTransparent ? 'bg-transparent' : ''}`} 
                    >
                        {(placeholder && placeholder.length) && <option selected disabled>{placeholder}</option>}
                        {(options && options.length) && options.map((option) => (<option value={option}>{option}</option>))}

                    </select>
                    <label htmlFor="floatingSelect">{label}</label>
                </div>
                {rest.endAdornment && (
                    <div className="floating-form-append">{rest.endAdornment}</div>
                )}
            </FloatingFormControl>
            <ErrorText className="text-danger font-size-sm">{rest.error ? helperText : ""}</ErrorText>
        </div>
        
    )
}

export const ThemeInputComponent = FloatingLabelForm;