import { DashboardContainer } from "../../../containers/dashboard/dashboardContainer";
import { Col, Row, Dropdown } from "react-bootstrap";
import { ReactComponent as QrCode } from "../../assets/svgs/demo-qr.svg";
import { CardColumnsContainer } from "../../../containers/dashboard/cardColums";
import { useAuth } from "../../../store/auth/hooks";
import { siteUrl } from "../../../utils/requests";
import QRCode from "react-qr-code";
import { jsPDF } from 'jspdf';
import { toast } from "react-toastify";


export const QRCodePage = () => {
    const { user } = useAuth();

    const generatePDF = async () => {
        const report = new jsPDF('portrait', 'pt', 'a4');
        const data = await document.querySelector("#qrCode");
        if(data){
            report.html((data as any)).then(() => {
                report.save('report.pdf');
            });
        }else{
            toast.error("Error downloading file");
        }
        
    }

    return (
        <DashboardContainer title="Your Connections">
            <Row className="py-3">
                <Col lg={4}>
                    <Dropdown>
                        <Dropdown.Toggle 
                            id="dropdown-basic" 
                            className="btn-themePrimary rounded-pill w-75"
                        >
                            Export
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/export-pdf" onClick={() => generatePDF()}>Export as PDF</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Export as PNG</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <br />

                    <CardColumnsContainer colProps={{ lg: 12 }} divder={false} title="QR Code">
                        <div id="qrCode" className="d-flex justify-content-center align-items-center pb-4">
                            <QRCode
                                value={siteUrl + "/connect/" + (user?.auth_id)}
                                size={window.innerWidth > 500 ? undefined : (window.innerWidth - 150)}
                            />
                        </div>
                    </CardColumnsContainer>
                </Col>
            </Row>
        </DashboardContainer>
    );
}