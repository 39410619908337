import styled from "@emotion/styled";
import { OutlinedInput, TextField } from "@mui/material";
import PhoneInput from 'react-phone-number-input';

export const InputWrapper = styled(OutlinedInput)`
    width: 100%;
    // font-size: 14px;
    // border-radius: 10px;
`;

export const FloatingPhoneControl = styled(PhoneInput)(`
    border: 1px solid #BDB2B2;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    input {
        border: none !important;
        flex-grow: 1;
        outline: none !important;
    }
    div.floating-form-append{
        padding-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    label {
        color: #606060;
    }
`);

export const FloatingFormControl = styled.div(`
    border: 1px solid #BDB2B2;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    input {
        border: none !important;
        flex-grow: 1;
        outline: none !important;
    }
    div.floating-form-append{
        padding-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    label {
        color: #606060;
    }
`);

export const ErrorText = styled.div(`
    font-size: 12px;
    padding-top: 4px;
    padding-left: 4px;
`)