import styled from '@emotion/styled';
import { Col, Card, Container } from 'react-bootstrap';
import { ReactComponent as ThumbIcon } from "../../assets/svgs/touch.svg";

export const AuthContainerWrapper = styled(Container)(`
    display: grid;
    place-content: center;
    height: 100vh;
`)

export const AuthContainer = styled.div(`
    border-radius: 8px;
    overflow: hidden;
    margin: 5em auto 5em auto;
    @media (max-width: 574px) { border: none; }
`)

export const AuthImageSection = styled(Col)(`
    background: #090C11;
    padding: 3rem;
`)

export const AuthHeaderSection = styled.div(`
    background: #090C11;
    padding: 1rem;
    display: flex;
    justify-content: center;
`)

export const ThumbIconSvg = styled(ThumbIcon)(`
    margin-top: -5em;
    max-width: -webkit-fill-available;

    @media (min-width: 1124px) {
        // width: 90% !important;
    }
`)

export const ProfileImage = styled.img(`
    border-radius: 50%; 
    height: 50px;
`)