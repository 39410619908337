import { Card, Row, Col } from "react-bootstrap"
import React from "react"
import { PrimaryButton } from "../../../components/buttons"
import { ConnectCardsContainer } from "../../../containers/connect/connectCards"
import { ReactComponent as FacebookSVG } from "../../../assets/svgs/facebook.svg";
import { ReactComponent as PhoneSVG } from "../../../assets/svgs/call-24-filled.svg";
import { ReactComponent as MessageSVG } from "../../../assets/svgs/message-filled.svg";
import { ReactComponent as MapsSVG } from "../../../assets/svgs/google-maps.svg";
import { ReactComponent as YoutubeSVG } from "../../../assets/svgs/youtube-icon.svg";
import { ReactComponent as TwitterSVG } from "../../../assets/svgs/twitter.svg";
import { ReactComponent as InstagramSVG } from "../../../assets/svgs/instagram-alt.svg";
import { ReactComponent as LinkedInSVG } from "../../../assets/svgs/linkedin.svg";
import { ReactComponent as ShareSVG } from "../../../assets/svgs/share.svg";
import { BoxedInfoTextComponent } from "../../../components/views/boxedInfoText";
import { SocialIconResizer, CardTitle } from "./styled";
import { RWebShare } from "react-web-share";
import { siteUrl } from "../../../utils/requests";


export const SocialsAndLinks: React.FC<{ user: any }> = ({ user }) => {

    const socialIcon = (data: string, icon: any, prefixed?: string) => {
        return (
            <Col>
                <SocialIconResizer 
                    href={prefixed ? `${prefixed}:${data}` : data}  
                    target={prefixed ? undefined : "_blank"}
                    disabled={!data || !data.length}
                >{icon}</SocialIconResizer>
            </Col>
        )
    }

    return (
        <ConnectCardsContainer>
            <CardTitle className="fw-bold mb-1 mb-lg-4">Social</CardTitle>
            <Row className="py-0">
                <Col xs={12} sm={6}>
                    <Row>
                        {socialIcon(user?.mobile_number, <PhoneSVG />, 'tel')}
                        {socialIcon(user?.email, <MessageSVG />, 'mailto')}
                        {socialIcon(user.social_link?.google_map, <MapsSVG />)}
                        {socialIcon(user.social_link?.youtube, <YoutubeSVG />)}
                    </Row>
                </Col>
                <Col xs={12} sm={6}>
                    <Row>
                        {socialIcon(user.social_link?.twitter, <TwitterSVG />)}
                        {socialIcon(user.social_link?.instagram, <InstagramSVG />)}
                        {socialIcon(user.social_link?.linkedin, <LinkedInSVG />)}
                        {socialIcon(user.social_link?.facebook, <FacebookSVG />)}
                    </Row>
                </Col>
            </Row>

            <div className="py-3 mt-lg-5 mt-1">
                <CardTitle className="fw-bold mb-1 mb-lg-4">Links</CardTitle>
                <BoxedInfoTextComponent title={"Email"} value={user?.email ?? 'None'} copy={(user?.email) ? true : false} />
                <BoxedInfoTextComponent title={"Website"} value={user?.social_link?.website ?? 'None'} copy={(user?.social_link?.website) ? true : false} />
            </div>

            <div className="d-flex justify-content-center">
                <RWebShare
                    data={{
                        text: "Share profile to your connections.",
                        url: "https://" + siteUrl + "/connect/" + (user.username ?? user.email),
                        title: "Share Profile",
                    }}
                    onClick={() => console.log("shared successfully!")}
                >
                    <PrimaryButton text={"Share Profile"} icon={<ShareSVG />} style={{ width: '250px' }} />
                </RWebShare>

            </div>
        </ConnectCardsContainer >
    )
}