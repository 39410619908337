import React from "react";
import { Col, Row } from "react-bootstrap";
import { DashboardContainer } from "../../../containers/dashboard/dashboardContainer";
import { SettingsInputComponent } from "./components/settingsInputComponent";
import { useState } from 'react';
import { useFetchColorThemeQuery, useUpdateColorThemeMutation } from "../../../store/user/api";
import { UpdateColorThemePayload } from "../../../store/user/interface";
import { LoadingViewContainer } from "../../../components/views/loadingView";
import { toast } from "react-toastify";
import { validationError } from '../../../utils/validations';
import { PrimaryButton } from "../../../components/buttons";

export const SettingsPage = () => {
  const { data: scheme, isLoading: colorLoading } = useFetchColorThemeQuery();
  const [update, { isLoading }] = useUpdateColorThemeMutation();
  const [form, setForm] = useState<UpdateColorThemePayload>({
    text_color: scheme?.data?.text_color ?? '',
    primary_button_text: scheme?.data?.primary_button.text ?? '',
    secondary_button_text: scheme?.data?.secondary_button.text ?? '',
    primary_button_background: scheme?.data?.primary_button.background ?? '',
    secondary_button_background: scheme?.data?.secondary_button.background ?? '',
    primary_button_border: scheme?.data?.primary_button.border ?? '',
    secondary_button_border: scheme?.data?.secondary_button.border ?? '',
    primary_hover_text: scheme?.data?.primary_hover.text ?? '',
    secondary_hover_text: scheme?.data?.secondary_hover.text ?? '',
    primary_hover_background: scheme?.data?.primary_hover.background ?? '',
    secondary_hover_background: scheme?.data?.secondary_hover.background ?? '',
    primary_hover_border: scheme?.data?.primary_hover.border ?? '',
    secondary_hover_border: scheme?.data?.secondary_hover.border ?? ''
  });
  const [formError, setFormError] = useState({
    text_color: false,
    primary_button_text: false,
    secondary_button_text: false,
    primary_button_background: false,
    secondary_button_background: false,
    primary_button_border: false,
    secondary_button_border: false,
    primary_hover_text: false,
    secondary_hover_text: false,
    primary_hover_background: false,
    secondary_hover_background: false,
    primary_hover_border: false,
    secondary_hover_border: false
  });

  
  const setValue = (param: keyof UpdateColorThemePayload, value: string) => {
    setForm((prev: any) => ({ ...prev, [param]: value }));
    setFormError((prev: any) => ({ ...prev, [param]: false }));
  }

  const handleSubmit = () => {
    if (form?.text_color.length && form?.text_color.length < 4) {
      setFormError((prev: any) => ({ ...prev, text_color: true }));
    } else if (form?.primary_button_text.length && form?.primary_button_text.length < 4) {
      setFormError((prev: any) => ({ ...prev, primary_button_text: true }));
    } else if (form?.secondary_button_text.length && form?.secondary_button_text.length < 4) {
      setFormError((prev: any) => ({ ...prev, secondary_button_text: true }));
    } else if (form?.primary_button_background.length && form?.primary_button_background.length < 4) {
      setFormError((prev: any) => ({ ...prev, primary_button_background: true }));
    } else if (form?.secondary_button_background.length && form?.secondary_button_background.length < 4) {
      setFormError((prev: any) => ({ ...prev, secondary_button_background: true }));
    } else if (form?.primary_button_border.length && form?.primary_button_border.length < 4) {
      setFormError((prev: any) => ({ ...prev, primary_button_border: true }));
    } else if (form?.secondary_button_border.length && form?.secondary_button_border.length < 4) {
      setFormError((prev: any) => ({ ...prev, secondary_button_border: true }));
    } else if (form?.primary_hover_text.length && form?.primary_hover_text.length < 4) {
      setFormError((prev: any) => ({ ...prev, primary_hover_text: true }));
    } else if (form?.secondary_hover_text.length && form?.secondary_hover_text.length < 4) {
      setFormError((prev: any) => ({ ...prev, secondary_hover_text: true }));
    } else if (form?.primary_hover_background.length && form?.primary_hover_background.length < 4) {
      setFormError((prev: any) => ({ ...prev, primary_hover_background: true }));
    } else if (form?.secondary_hover_background.length && form?.secondary_hover_background.length < 4) {
      setFormError((prev: any) => ({ ...prev, secondary_hover_background: true }));
    } else if (form?.primary_hover_border.length && form?.primary_hover_border.length < 4) {
      setFormError((prev: any) => ({ ...prev, primary_hover_border: true }));
    } else if (form?.secondary_hover_border.length && form?.secondary_hover_border.length < 4) {
      setFormError((prev: any) => ({ ...prev, secondary_hover_border: true }));
    } else {
      update(form).unwrap().then((res) => {
        toast.success(res?.response);
      }).catch((error) => {
        console.log(error);
        toast.error(error?.message ?? validationError(error?.data?.response));
      })
    }
  }

  return (
    <DashboardContainer title="Settings">
      {(colorLoading) ? (
        <LoadingViewContainer />
      ) : (
        <Col className="p-3">
          <Row lg={3}>
            <SettingsInputComponent
              header={"Body Text Color"}
              value={form.text_color}
              setValue={(value) => setValue('text_color', value)}
              error={formError.text_color}
              helperText="Enter a valid Body Text Color"
            />
          </Row>
          <Col className="py-2">
            <h3>Default</h3>
            <Row lg={3}>
              <SettingsInputComponent 
                header={"Primary Button Text Color"}
                value={form.primary_button_text}
                setValue={(value) => setValue('primary_button_text', value)}
                error={formError.primary_button_text}
                helperText="Enter a valid Primary Button Text Color"
              />
              <SettingsInputComponent header={"Secondary Button Text Color"}
                value={form.secondary_button_text}
                setValue={(value) => setValue('secondary_button_text', value)}
                error={formError.secondary_button_text}
                helperText="Enter a valid Secondary Button Text Color"
              />
            </Row>
            <Row lg={3}>
              <SettingsInputComponent
                header={"Primary Button Background Color"}
                value={form.primary_button_background}
                setValue={(value) => setValue('primary_button_background', value)}
                error={formError.primary_button_background}
                helperText="Enter a valid Primary Button Background Color"
              />
              <SettingsInputComponent
                header={"Secondary Button Background Color"}
                value={form.secondary_button_background}
                setValue={(value) => setValue('secondary_button_background', value)}
                error={formError.secondary_button_background}
                helperText="Enter a valid Secondary Button Background Color"
              />
            </Row>
            <Row lg={3}>
              <SettingsInputComponent header={"Primary Button Border Color"}
                value={form.primary_button_border}
                setValue={(value) => setValue('primary_button_border', value)}
                error={formError.primary_button_border}
                helperText="Enter a valid Primary Button Border Color"
              />
              <SettingsInputComponent header={"Secondary Button Border Color"}
                value={form.secondary_button_border}
                setValue={(value) => setValue('secondary_button_border', value)}
                error={formError.secondary_button_border}
                helperText="Enter a valid Secondary Button Border Color"
              />
            </Row>
          </Col>
          <Col className="py-2">
            <h3>Hover</h3>
            <Row lg={3}>
              <SettingsInputComponent header={"Primary Button Text Color"}
                value={form.primary_hover_text}
                setValue={(value) => setValue('primary_hover_text', value)}
                error={formError.primary_hover_text}
                helperText="Enter a valid Primary Button Text Color"
              />
              <SettingsInputComponent header={"Secondary Button Text Color"}
                value={form.secondary_hover_text}
                setValue={(value) => setValue('secondary_hover_text', value)}
                error={formError.secondary_hover_text}
                helperText="Enter a valid Secondary Button Text Color"
              />
            </Row>
            <Row lg={3}>
              <SettingsInputComponent
                header={"Primary Button Background Color"}
                value={form.primary_hover_background}
                setValue={(value) => setValue('primary_hover_background', value)}
                error={formError.primary_hover_background}
                helperText="Enter a valid Primary Button Background Color"
              />
              <SettingsInputComponent
                header={"Secondary Button Background Color"}
                value={form.secondary_hover_background}
                setValue={(value) => setValue('secondary_hover_background', value)}
                error={formError.secondary_hover_background}
                helperText="Enter a valid Secondary Button Background Color"
              />
            </Row>
            <Row lg={3}>
              <SettingsInputComponent header={"Primary Button Border Color"}
                value={form.primary_hover_border}
                setValue={(value) => setValue('primary_hover_border', value)}
                error={formError.primary_hover_border}
                helperText="Enter a valid Primary Button Border Color"
              />
              <SettingsInputComponent header={"Secondary Button Border Color"}
                value={form.secondary_hover_border}
                setValue={(value) => setValue('secondary_hover_border', value)}
                error={formError.secondary_hover_border}
                helperText="Enter a valid Secondary Button Border Color"
              />
            </Row>
          </Col>
            <PrimaryButton
              text={"Update"}
              style={{ width: '300px' }}
              isLoading={isLoading}
              onClick={() => handleSubmit()}
            />
        </Col>
      )}
    </DashboardContainer>
  );
};
