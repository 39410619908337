import { Card, Carousel, Col, Row } from "react-bootstrap"
import { ReactComponent as LeftIcon } from "../../../assets/svgs/arrow-left-circle.svg";
import { ReactComponent as RightIcon } from "../../../assets/svgs/arrow-right-circle.svg";
import { ReactComponent as DemoQRCode } from "../../../assets/svgs/demo-qr.svg";
import { ConnectCardsContainer } from "../../../containers/connect/connectCards";
import { CardTitle } from "./styled";
import QRCode from "react-qr-code";
import { ProfileData } from "../../../store/user/interface";
import { baseUrl, siteUrl } from "../../../utils/requests";


export const GalleryAndQR: React.FC<{ user: ProfileData }> = ({ user }) => {

    return (
        <ConnectCardsContainer>
            <Row>
            { 
                (user?.photo?.gallery && user?.photo?.gallery.length > 0) ?
                    <Col md={6}>
                        <CardTitle className="fw-bold text-center text-lg-start">Gallery</CardTitle>
                        <div className="py-3">
                            {(user?.photo?.gallery && user?.photo?.gallery.length) ? (
                                <Carousel
                                    nextIcon={<RightIcon />}
                                    prevIcon={<LeftIcon />}
                                >
                                    {(user.photo.gallery ?? []).map((photo) => (
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={photo}
                                                alt={photo}
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            ) : (
                                <div className="h-100 bg-light d-flex justify-content-center align-items-center" style={{ minHeight: '500px' }}>
                                    <h5>No images yet!</h5>
                                </div>
                            )}
                        </div>
                    </Col>
                    : " "
                }
                <Col md={user?.photo?.gallery && user?.photo?.gallery.length > 0 ? 6 : 12}>
                    <CardTitle className="fw-bold text-center text-lg-start mt-5 mt-lg-0">QR Code</CardTitle>
                    <div className="py-3 d-flex justify-content-center align-items-center">
                        <QRCode
                            value={"https://" + siteUrl + "/connect/" + (user?.username ?? user?.email)}
                            size={window.innerWidth > 500 ? (window.innerWidth / 3.5) : (window.innerWidth - 150)}
                        />
                    </div>
                </Col>
            </Row>
        </ConnectCardsContainer>
    )
}