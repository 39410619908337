import { PrimaryButton } from "../../components/buttons";
import { AuthBoxedContainer } from "../../containers/auth/authBoxedContainer";
import { LinkComponent } from "../../components/texts/styled";
import { useState } from "react";
import { FloatingLabelForm, ThemeInputComponent } from "../../components/inputs/themeInput";
import { LoginPayload } from "../../store/auth/interface";
import { useLoginMutation } from "../../store/auth/api";
import { CustomPasswordInput } from "./components/passwordInput";
import { setCredential } from "../../store/auth"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { validateEmail, validationError } from '../../utils/validations';
import { BaseViewContainer } from "containers/main/baseViewContainer";

const LoginPage = () => {
    const [form, setForm] = useState<LoginPayload>({ auth: '', password: '' });
    const [formError, setFormError] = useState({ auth: false, password: false });
    const [loginUser, { isLoading }] = useLoginMutation();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const setValue = (param: keyof LoginPayload, value: string) => {
        setForm((prev: any) => ({ ...prev, [param]: value }));
        setFormError((prev: any) => ({ ...prev, [param]: false }));
    }

    const initLoginUser = () => {
        // || !validateEmail(form?.email)
        if (form?.auth.length < 1 ) {
            setFormError((prev: any) => ({ ...prev, email: true }));
        } else if (form?.password.length < 8) {
            setFormError((prev: any) => ({ ...prev, password: true }));
        } else {
            loginUser(form).unwrap().then((response) => {
                // console.log(response);
                dispatch(setCredential({user: response.data.user_data, access_token: response.data.access_token}))
                navigate("/dashboard/edit-profile");
            }).catch((error) => {
                // console.log(error);
                toast.error(error?.message ?? validationError(error?.data?.response));
            });
        }
    }

    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <AuthBoxedContainer title={'Login'}>
                <p>Welcome back!</p>
                <div className="row">
                    <FloatingLabelForm 
                        type="email" 
                        label="Email or Username" 
                        name="auth"
                        value={form.auth}
                        inputBgTransparent={true}
                        placeholder="Email or Username" 
                        setValue={(value) => setValue('auth', value)}
                        error={formError.auth}
                        helperText="Kindly enter a valid email address."
                    />          
                    <CustomPasswordInput
                        placeholder="Password"
                        value={form.password}
                        inputBgTransparent={true}
                        setValue={(value) => setValue('password', value)}
                        error={formError.password}
                        helperText="Password is required."
                    />
                </div>

                <div className="d-flex justify-content-end">
                    <LinkComponent to="/auth/password/forgot">Forgot Password</LinkComponent>
                </div>
                <br />
                <div className="mt-3 mb-5">
                    <PrimaryButton 
                        text={"Login"} 
                        isLoading={isLoading}
                        onClick={() => initLoginUser()}
                    />
                </div>
            </AuthBoxedContainer>
        </BaseViewContainer>
    )
}

export default LoginPage;