import {  Form, InputGroup } from "react-bootstrap";
import { PrimaryButton } from "../../components/buttons";
import { AuthBoxedContainer } from "../../containers/auth/authBoxedContainer";
import { LinkComponent } from "../../components/texts/styled";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FloatingLabelForm } from "../../components/inputs/themeInput";
import { useForgotMutation } from "../../store/auth/api";
import { BaseViewContainer } from "containers/main/baseViewContainer";

export const ForgotPasswordPage = () => {
    const [forgotPassword, {isLoading}] = useForgotMutation();

    const [email, setEmail] = useState("");
    const handleSubmit = ( ) => {
          console.log(email);  
        forgotPassword(email).unwrap().then((res) => {
            console.log(res);
            if(res.status){
                alert("Password reset link has been sent to your email");
            }else{
                alert("Email not found");
            }
        }).catch(
            (err) => {
                console.log(err);
            }
        );        
    }


    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <AuthBoxedContainer title={'Forgot Password'}>
                <p>Input your email address, a link would be sent to reset your password</p>
                    <FloatingLabelForm 
                        type="email" 
                        label="Email Address" 
                        name="email" 
                        inputBgTransparent={true}
                        placeholder="Email Address"
                        value = {email}
                        setValue = {setEmail}
                    />

                <Link to="" onClick={handleSubmit}>
                <PrimaryButton text={"Submit"} />
                </Link>
            </AuthBoxedContainer>
        </BaseViewContainer>
    )
}

