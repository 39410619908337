import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums";
import { BoxedInfoInputComponent } from "../../../../components/views/boxedInfoInput";
import { PrimaryButton } from "../../../../components/buttons"
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { ProfileData, UpdateSocialsPayload } from "../../../../store/user/interface";
import { useUpdateSocialsMutation } from "../../../../store/user/api";
import { toast } from "react-toastify";
import { isValidURL, validationError } from '../../../../utils/validations';

type IProps = {
  user?: ProfileData
}

export const EditSocialLinks = ({
  user
}: IProps) => {
  const [update, { isLoading }] = useUpdateSocialsMutation();
  const [form, setForm] = useState<UpdateSocialsPayload>({
    auth_id: user?.auth_id ?? '',
    email: user?.social_link?.email  ?? '',
    google_map: user?.social_link?.google_map  ?? '',
    youtube: user?.social_link?.youtube  ?? '',
    linkedin: user?.social_link?.linkedin  ?? '',
    facebook: user?.social_link?.facebook  ?? '',
    twitter: user?.social_link?.twitter  ?? '',
    instagram: user?.social_link?.instagram  ?? '',
    website: user?.social_link?.website  ?? '',
  });
  const [formError, setFormError] = useState({
    email: false,
    google_map: false,
    youtube: false,
    linkedin: false,
    facebook: false,
    twitter: false,
    instagram: false,
    website: false,
  });

  const setValue = (param: keyof UpdateSocialsPayload, value: string) => {
    setForm((prev: any) => ({ ...prev, [param]: value }));
    setFormError((prev: any) => ({ ...prev, [param]: false }));
  }

  const handleSubmit = () => {
    if (form?.email?.length && !isValidURL(form?.email)) {
      setFormError((prev: any) => ({ ...prev, email: true }));
    } else if (form?.google_map?.length && !isValidURL(form?.google_map)) {
      setFormError((prev: any) => ({ ...prev, google_map: true }));
    } else if (form?.youtube?.length && !isValidURL(form?.youtube)) {
      setFormError((prev: any) => ({ ...prev, youtube: true }));
    } else if (form?.linkedin?.length && !isValidURL(form?.linkedin)) {
      setFormError((prev: any) => ({ ...prev, linkedin: true }));
    } else if (form?.facebook?.length && !isValidURL(form?.facebook)) {
      setFormError((prev: any) => ({ ...prev, facebook: true }));
    } else if (form?.twitter?.length && !isValidURL(form?.twitter)) {
      setFormError((prev: any) => ({ ...prev, twitter: true }));
    } else if (form?.instagram?.length && !isValidURL(form?.instagram)) {
      setFormError((prev: any) => ({ ...prev, instagram: true }));
    } else if (form?.website?.length && !isValidURL(form?.website)) {
      setFormError((prev: any) => ({ ...prev, website: true }));
    } else {
      const payload: any = {}
      Object.keys(form).forEach((element) => {
        const key = (element as keyof UpdateSocialsPayload);
        if (form[key] && form[key]?.length) payload[key] = form[key];
      });
      // console.log(form, payload);
      update(payload).unwrap().then((res) => {
        toast.success(res?.response);
      }).catch((error) => {
        // console.log(error);
        toast.error(error?.message ?? validationError(error?.data?.response));
      })
    }
  }

  return (
    <CardColumnsContainer
      colProps={{ lg: 12 }}
      title="Social Links"
      subTitle="Please provide your links below"
    >
      <Row>
        <Col md={6}>
          <BoxedInfoInputComponent
            title="Google Map"
            placeholder="Your link here"
            value={form?.google_map}
            setValue={(value) => setValue('google_map', value)}
            error={formError.google_map}
            helperText="Ender a valid Google Maps link!"
          />
          <BoxedInfoInputComponent
            title="Youtube"
            value={form?.youtube}
            placeholder="Your link here"
            setValue={(value) => setValue('youtube', value)}
            error={formError.youtube}
            helperText="Ender a valid youtube link"
          />
          <BoxedInfoInputComponent
            title="LinkedIn"
            value={form?.linkedin}
            placeholder="Your link here"
            setValue={(value) => setValue('linkedin', value)}
            error={formError.linkedin}
            helperText="Ender a valid linkedin link!"
          />
          <BoxedInfoInputComponent
            title="Facebook"
            value={form?.facebook}
            placeholder="Your link here"
            setValue={(value) => setValue('facebook', value)}
            error={formError.facebook}
            helperText="Ender a valid facebook link!"
          />
        </Col>
        <Col md={6}>
          <BoxedInfoInputComponent
            title="Twitter"
            value={form?.twitter}
            placeholder="Your link here"
            setValue={(value) => setValue('twitter', value)}
            error={formError.twitter}
            helperText="Ender a valid twitter link!"
          />
          <BoxedInfoInputComponent
            title="Instagram"
            value={form?.instagram}
            placeholder="Your link here"
            setValue={(value) => setValue('instagram', value)}
            error={formError.instagram}
            helperText="Ender a valid instagram link!"
          />
          <BoxedInfoInputComponent
            title="Email"
            value={form?.email}
            placeholder="Your link here"
            setValue={(value) => setValue('email', value)}
            error={formError.email}
            helperText="Enter a valid email address."
          />
          <BoxedInfoInputComponent
            title="Website"
            value={form?.website}
            placeholder="Your link here"
            setValue={(value) => setValue('website', value)}
            error={formError.website}
            helperText="Ender a valid website link!"
          />
          <div className="d-flex justify-content-end pb-3">
            <PrimaryButton
              text={"Update"}
              style={{ width: '300px' }}
              isLoading={isLoading}
              onClick={() => handleSubmit()}
            />
          </div>
        </Col>
      </Row>

    </CardColumnsContainer>
  );
};
