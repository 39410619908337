import { 
    TextTitle, 
    SubText, FlexibleContainer, 
    ThemeAccordion, Button, 
    Indicator, TextIndicator, HeroText
} from "./styled"
import UserMobileDashboard from "assets/svgs/user-mobile-dashboard.svg";
import QrCodeCard from "assets/svgs/card.svg";
import ConnectPage from "assets/svgs/connect-page.svg";
import TapitHeroSection from "assets/images/hero.gif";
import Accordion from 'react-bootstrap/Accordion';
import Faqs from "assets/jsons/faq.json";
import { BaseViewContainer } from "containers/main/baseViewContainer";

export const HomePage = () => {
    return (
        <BaseViewContainer>
            <FlexibleContainer alignItems={true} className="row mx-auto g-md-5 g-0" margin="3em auto 3em auto" maxWidth="92%">
                <div className="col-md-5 col-sm-12 mt-0">
                    <HeroText>Smart business card with just a tap.</HeroText>
                    <SubText>Share your contact card, social media, business pitch and everything else with anyone wherever you go in a matter of seconds.</SubText>
                    <Button href="/products" className="mt-3">Get Smart Card Now</Button>
                </div>
                <div className="col-md-7 col-sm-12 float-md-end float-start">
                    <img className="img-fluid" src={TapitHeroSection} />
                </div>
            </FlexibleContainer>
            <FlexibleContainer alignItems={true} className="row g-5 mx-auto" margin="3em auto 3em auto" maxWidth="78%">
                <div className="col-md-5 col-sm-12 mb-md-0 mb-5">
                    <img className="img-fluid" src={UserMobileDashboard} width="400" />
                </div>
                <div className="col-md-7 col-sm-12 order-first order-md-last">
                    <TextTitle>More options with a single tap.</TextTitle>
                    <SubText>When the save contact button is tapped, your information is instantly saved to the receiver's phone. You can also receive information by if the user clicks on 'Exchange contact' button. More also, you can update information can be updated at any time by logging into your profile. </SubText>
                    <Button href="/products" className="mt-3">Shop Now</Button>
                </div>
            </FlexibleContainer>
            <FlexibleContainer alignItems={true} className="row g-5 mx-auto" margin="3em auto 3em auto" maxWidth="78%">
                <div className="col-md-6 col-sm-12">
                    <TextTitle>One profile, more ways to connect.</TextTitle>
                    <SubText>With our phone tag and smart card option, your friend can easily save your contact and connect with you on your social media with just a simple tap. There is no special app required.</SubText>
                    <Button className="mt-4" href="/products">Explore our Products</Button>
                </div>
                <div className="col-md-6 col-sm-12 order-sm-1">
                    <img className="img-fluid" src={QrCodeCard} />
                </div>
            </FlexibleContainer>
            <FlexibleContainer alignItems={true} className="row g-lg-0 g-5 mx-auto" margin="10em auto 5em auto" maxWidth="70%">
                <div className="text-center  mb-3 d-md-block d-none"><TextTitle>How it works</TextTitle></div>
                <div className="col-md-6 col-sm-12 text-center">
                    <img className="img-fluid" src={ConnectPage} />
                </div>
                <div className="col-md-6 col-sm-12 mb-md-0 mb-5 order-first order-md-last">
                    <div className="text-center mb-3 d-md-none d-block"><TextTitle>How it works</TextTitle></div>
                    <div className="d-flex">
                        <Indicator>
                            <li className="indicator-number">1</li>
                            <li className="indicator-number">2</li>
                            <li className="indicator-number">3</li>
                        </Indicator>
                        <TextIndicator>
                            <div>
                                <h5 className="fw-bold">Purchase a card or tag</h5>
                                <SubText className="indicator-text">Purchase your card or tag to get started.</SubText>
                            </div>
                            <div style={{ margin: "3.6em 0"}} className="mt-lg-5 mt-4">
                                <h5 className="fw-bold">Setup your profile</h5>
                                <SubText className="indicator-text">After successfully purchasing your smart card or phone tag, A login details would be sent to you to set up your profile.</SubText>
                            </div>
                            <div className="mt-lg-5 mt-4">
                                <h5 className="fw-bold">Start tapping</h5>
                                <SubText className="indicator-text">With just a tap, your loved ones ans customers cab save your contact, exchange contact and connect with you on social media.</SubText>
                            </div>
                        </TextIndicator>
                    </div>
                </div>
            </FlexibleContainer>
            <FlexibleContainer alignItems={true} className="row mx-auto" maxWidth="78%" margin="5em auto 3em auto" id="faq">
                <div className="col-lg-4 col-md-5 col-sm-12 mt-lg-0 mt-5">
                    <TextTitle style={{ fontWeight: 600}} className="text-md-start text-center">Frequently Asked Questions</TextTitle>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12">
                    <ThemeAccordion defaultActiveKey="0">
                        {Faqs.length > 0 ? (
                            Faqs.map((data) => (
                                <Accordion.Item eventKey={data.eventKey}>
                                    <Accordion.Header className="bg-white fw-bold">{data.header}</Accordion.Header>
                                    <Accordion.Body>{data.body}</Accordion.Body>
                                </Accordion.Item>
                            ))
                        ): (<></>)}
                    </ThemeAccordion>
                </div>
            </FlexibleContainer>
        </BaseViewContainer>
    )
}