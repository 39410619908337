import { DashboardContainer } from "../../../containers/dashboard/dashboardContainer";
import { Col, Row } from "react-bootstrap";
import { CardConnection } from "./components/cardConnection";
import { CardColumnsContainer } from "../../../containers/dashboard/cardColums";
import { useFetchConnectionsQuery } from "../../../store/user/api";
import { ReactComponent as CopyIcon } from "../../../assets/svgs/copy-blue.svg";
import { ReactComponent as CallIcon } from "../../../assets/svgs/call-icon.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useAuth } from "../../../store/auth/hooks";

export const UserConnectionPage = () => {
    const { user } = useAuth();
    const auth_id = user?.auth_id ?? '';
    const { data, isLoading } = useFetchConnectionsQuery({auth_id});

    return (
        <DashboardContainer title="Your Connections">
            <Row className="py-3">
                <Col md={4}>
                    <CardConnection  total={data?.data?.total}/>
                </Col>
                <Col md={8}>
                    <CardColumnsContainer colProps={{ lg: 12 }} divder={false}>
                        <div className="table-responsive">
                            <table className='table'>
                                <thead className='table-secondary'>
                                    <tr style={{ fontWeight: 200 }}>
                                        <th>S/N</th>
                                        <th>Name</th>
                                        <th>Phone Number</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(data?.data?.connections ?? []).map((con, index) => (
                                        <tr>
                                            <th>{index + 1}</th>
                                            <th>{con.contact_name}</th>
                                            <th>{con.contact_phone}</th>
                                            <th>
                                                <div className='d-flex touchable'>
                                                    <CopyToClipboard text={con.contact_phone}
                                                        onCopy={() => alert(`Contact phone number copied.`)}>
                                                        <CopyIcon />
                                                    </CopyToClipboard>
                                                    &nbsp;&nbsp;
                                                    <a href={`tel:${con.contact_phone}`}>
                                                        <CallIcon />
                                                    </a>
                                                </div>
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </CardColumnsContainer>
                </Col>
            </Row>
        </DashboardContainer>
    );
}