import { NavigationBar } from './components/navigationBar'
import { NavFooter } from './components/navFooter';
import { Snackbar, BaseWrapper } from './styled';
import { ReactComponent as WhatsApp } from "assets/svgs/whatsapp.svg";
import { Outlet } from 'react-router-dom';


export const BaseViewWrapper = () => {
    return (
        <>
            <NavigationBar />
            <BaseWrapper id="main-app-wrapper">
                <Outlet />
                <a target="_blank" href='https://api.whatsapp.com/send?phone=2347066020900'>
                    <Snackbar>
                        <span className="mb-0 me-3 text-dark">How can we be of help? </span>
                        <WhatsApp width="40" height="40"  />
                    </Snackbar>
                </a>
            </BaseWrapper>
            <NavFooter />
        </>
    )
}