import styled from "@emotion/styled";
import { Card } from "react-bootstrap"

export const CardConnectionSection = styled(Card)(`
    display: flex;
    color: #fff;
    text-align: center;
    background: #090C11;
    border-radius: 0.8em;
    padding: 5em 0;
    justify-content: center;
`);