import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const Divider = styled.div`
  border: 0.5px solid #b9b9b9;
  margin: 20px 0;
`;

export const BoxedTextContainer = styled.div<{error?: boolean}>(({error}) => (`
    border: 0.5px solid ${(!error) ? '#B9B9B9' : 'red'};
    margin: 10px 0;
    border-radius: 10px;
    display: block;
    @media (min-width: 576px) {
        display: flex;
    }
`));

export const BoxedTextTitle = styled.div(`
    border-bottom: 1px solid #B9B9B9;
    font-weight: 500;
    padding: 18px 32px;
    @media (min-width: 576px) {
        min-width: 200px;
        border-right: 1px solid #B9B9B9;
        border-bottom: none !important;
    }

    @media (max-width: 576px) {
        padding: 12px 32px !important;
    }
`);

export const BoxedTextValue = styled.div(`
    padding-left: 1rem;
    padding: 18px 32px;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    @media (max-width: 576px) {
        padding: 12px 32px !important;
    }
`);
export const BoxedInputContainer = styled.div(`
    border: 0.5px solid #B9B9B9;
    margin: 10px 0;
    // margin-left:10px;
    border-radius: 10px;
    display: block;
    @media (min-width: 576px) {
        display: flex;
        width:100%;
    }
    `);

export const BoxedInputTitle = styled.div(`
    border-bottom: 1px solid #B9B9B9;
    font-weight: 500;
    font-size: 14px;    
    padding: 15px 5px 15px 15px;
    @media (min-width: 576px) {
        min-width: 140px;
        border-right: 1px solid #B9B9B9;
        border-bottom: none !important;
    }
`);

export const BoxedInputValue = styled.div(`
    padding-left: 1rem;
    padding: 15px;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
`);

export const BoxedInput = styled.input(`   
    outline : none ; 
    border: none;
    font-size: 14px; 
    flex-grow: 1;       
`);
// padding: 24px 32px;
