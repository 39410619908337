import { createApi} from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosQuery/axiosBaseQuery';
import { baseUrl } from '../../utils/requests';
import { AuthResponse, IResponse, LoginPayload } from './interface';


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/` }),
    endpoints: (builder) => ({
        login: builder.mutation<AuthResponse, LoginPayload>({
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials
            })
        }),
        register: builder.mutation({
            query: (credentials) => ({
                url: 'auth/register',
                method: 'POST',
                body: credentials
            })
        }),
        forgot: builder.mutation({
            query: (email) => ({
                url: 'auth/password/forgot',
                method: 'POST',
                body: {email: email}
            })
        }),
        reset: builder.mutation({
            query: (credentials) => ({
                url: 'auth/password/reset',
                method: 'POST',
                body: credentials
            })
        }),
        verifyPayment: builder.mutation({
            query: (credentials) => ({
                url: 'transaction/verify',
                method: 'POST',
                body: credentials
            })
        }),
        resendVerifyLink: builder.mutation<IResponse, string>({
            query: (email) => ({
                url: 'auth/mail/resend',
                method: 'POST',
                body: { email: email }
            })
        }),
        logOut: builder.mutation<IResponse, void>({
            query: (credentials) => ({
                url: 'auth/token/destroy',
                method: 'GET',
                body: credentials
            })
        })
    })
});


export const {
    useLoginMutation,
    useRegisterMutation,
    useForgotMutation,
    useResetMutation,
    useVerifyPaymentMutation,
    useResendVerifyLinkMutation,
    useLogOutMutation
} = authApi;

    