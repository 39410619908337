import currencyFormatter from "utils/currencyFormatter";
import { useState } from "react";
import { CartProductData } from "store/product/cart"

interface CartTableCellProps {
    item: CartProductData,
    removeItem?: (item: CartProductData) => void;
    onChangeQuantity?: (quantity: number) => void;
    isLoading?: boolean
}

export const CartTableCell: React.FC<CartTableCellProps> = ({
    item,
    isLoading,
    removeItem = () => { },
    onChangeQuantity = () => { }
}) => {
    const [quantity, setQuantity] = useState(item.quantity);

    const toggleQuantityAmount = (action: 'add' | 'minus') => {
        const max = 1000000000;
        const min = 1;
        let newQuantity = quantity;

        if (action === 'add' && item.quantity < max) newQuantity++;

        if (action === 'minus' && item.quantity > min) newQuantity--;

        setQuantity(newQuantity);
        onChangeQuantity(newQuantity);
    }

    return (
        <tr className="py-3">
            <td>
                <div className="d-flex g-3">
                    <div className="me-3">
                        <img
                            src={item.image}
                            className={"img-fluid rounded rounded-3"}
                            width={90}
                        />
                    </div>
                    <div className="align-self-center">
                        <h6 className="mb-1">{item.name}</h6>
                        <a
                            href={"#remove-" + item.guid}
                            className={"text-decoration-underline"}
                            onClick={() => removeItem(item)}
                        >
                            Remove
                        </a>
                    </div>
                </div>
                <div className="d-md-none d-block d-flex">
                    <div className="input-group" style={{ flexWrap: "unset"}}>
                        <span
                            className="input-group-text bg-transparent touchable"
                            onClick={() => (isLoading ? null : toggleQuantityAmount('add'))}
                        >+</span>
                        <input
                            type="text"
                            value={quantity}
                            style={{ maxWidth: '15%', textAlign: 'center'}}
                            className="bg-transparent border-1"
                            placeholder="Quantity eg: 5"
                            onChange={({ target }) => setQuantity(parseInt(target.value) ? parseInt(target.value) : 1)}
                            readOnly={true}
                            min={1}
                        />
                        <span
                            className="input-group-text bg-transparent touchable"
                            onClick={() => (isLoading ? null : toggleQuantityAmount('minus'))}
                        >-</span>
                        
                    </div>
                    {Boolean(isLoading && (item.quantity !== quantity)) && (
                        <div className="align-self-center ms-2">
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                </div>
            </td>
            <td style={{paddingBottom: "4.8em"}} className="d-md-block d-none">
                <div className="d-flex">
                    <div className="input-group" style={{ flexWrap: "unset"}}>
                        <span
                            className="input-group-text bg-transparent touchable"
                            onClick={() => (isLoading ? null : toggleQuantityAmount('add'))}
                        >+</span>
                        <input
                            type="text"
                            value={quantity}
                            style={{ maxWidth: '15%', textAlign: 'center'}}
                            className="bg-transparent border-1"
                            placeholder="Quantity eg: 5"
                            onChange={({ target }) => setQuantity(parseInt(target.value) ? parseInt(target.value) : 1)}
                            readOnly={true}
                            min={1}
                        />
                        <span
                            className="input-group-text bg-transparent touchable"
                            onClick={() => (isLoading ? null : toggleQuantityAmount('minus'))}
                        >-</span>
                        
                    </div>
                    {Boolean(isLoading && (item.quantity !== quantity)) && (
                        <div className="align-self-center ms-2">
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                </div>
            </td>
            <td className="text-end">
                <strong className="text-theme-primary">
                    ₦{currencyFormatter(item.amount * item.quantity)}
                </strong>
            </td>
        </tr>
    )
}