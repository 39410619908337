import { useMemo } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ProductData } from '../interface';

const cartStorageId = '@tap_it_user_cart';

export interface CartProductData extends ProductData {
    quantity: number
}

export const useManageCartItems = () => {
    // loading state
    let isLoading = false;
    let isFetching = false;

    // get cart items stored in localstorgae
    let getItems = () => {
        isFetching = true;

        const result = reactLocalStorage.get(cartStorageId);
        const items = result ? (JSON.parse(result) as CartProductData[]) : [];

        isFetching = false;
        return items;
    }

    // set a group of items as current cart items 
    let setItems = async (items: CartProductData[]) => {
        try {
            reactLocalStorage.set(cartStorageId, JSON.stringify(items));
            return true;
        } catch (error) {
            return false;
        }
    }

    let addItem = (item: CartProductData) => {
        isLoading = true;
        const items = getItems();

        return new Promise((resolve, reject) => {
            const found = items.find(i => i.guid === item.guid);

            if (!found) {
                items.push(item);
                setItems(items).then((status) => {
                    if (status) {
                        resolve('Your item has been added to cart.')
                    } else {
                        reject('Error adding item to cart, kindly try again or contact admin if issue persist.');
                    }
                    isLoading = false;
                })
            } else {
                reject('Item already exist in cart, kindly increase quantity to add more of this item.');
                isLoading = false;
            }
        })
    }

    let updateItem = (param: keyof CartProductData, value: any, item: CartProductData) => {
        isLoading = true;
        let items = getItems();

        return new Promise((resolve, reject) => {
            const found = items.findIndex(i => i[param] === value);

            if (found !== undefined) {
                items[found] = item;
                setItems(items).then((status) => {
                    if (status) {
                        resolve('Your item has been updated in cart.')
                    } else {
                        reject('Error updating item in cart, kindly try again or contact admin if issue persist.');
                    }
                    isLoading = false;
                })
            } else {
                reject('Error updating item in cart, kindly try again or contact admin if issue persist.');
                isLoading = false;
            }
        })
    }

    let removeItem = (item: CartProductData) => {
        isLoading = true;
        const items = getItems();

        return new Promise((resolve, reject) => {
            const newItems = items.filter(i => (i.guid !== item.guid));

            setItems(newItems).then((status) => {
                if (status) {
                    resolve('Your item has been removed from cart.')
                } else {
                    reject('Error removing item from cart, kindly try again or contact admin if issue persist.');
                }
                isLoading = false;
            })

        })
    }


    const rawJSONData = reactLocalStorage.get(cartStorageId);
    const items = getItems();

    return useMemo(() => ({
        rawJSONData,
        items,
        addItem,
        removeItem,
        updateItem,
        setItems,
        getItems,
        isLoading,
        isFetching
    }), [
        rawJSONData,
        items,
        addItem,
        removeItem,
        updateItem,
        setItems,
        isLoading,
        isFetching
    ]);
}

