import React, { useState } from "react"
import {  Col, Row } from "react-bootstrap"
import { OutlinedButton, PrimaryButton } from "../../../components/buttons"
import { ConnectCard } from "../../../containers/connect/styled"
import { ReactComponent as AddSvg } from "../../../assets/svgs/add.svg";
import { ReactComponent as LocationSvg } from "../../../assets/svgs/location.svg";
import {
    ConnectCoverSection,
    UserProfilePicture,
    CompanyLogo,
    ConnectButtonContainer
} from "./styled"
import { ProfileData } from "../../../store/user/interface";
import FileSaver from "file-saver";
import { ThemeModal } from "./modal";
import { ExchangeContactsComponent } from "./exchangeContactForm";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/requests";


export const UserProfileSummary: React.FC<{user: ProfileData}> = ({user}) => {
    const [modalActive, setModalActive] = React.useState(false);
    const [simLoad, setSimLoad] = useState({vcard: false});

    const availableImage = (type: string = "profile") => {
        if(type === "profile"){
            if (user?.photo?.avatar) return user?.photo?.avatar;
            return require("../../../assets/images/user-default-img.png");
        }else if (type === "biz") {
            if (user?.company?.logo) return user?.company?.logo;
            return require("../../../assets/images/user-default-img.png")
        }else{
            if (user?.photo?.cover) return user?.photo?.cover;
            return require("../../../assets/images/cover-photo-default.png")
        }
    }

    function getContact() {
        // return console.log("starting...");
        setSimLoad((prev) => ({ ...prev, vcard: true }));
        try{
        const names = user.name.split(" ");
        const card = `BEGIN:VCARD
VERSION:3.0
N:${names[0] ?? ''};${names[1] ?? ''};;;
FN:${names[0] ?? ''} ${names[1] ?? ''}
TITLE:TAP-IT Profile;
EMAIL;TYPE=work:${user.email}
TEL;type=CELL;type=VOICE;type=pref:${user?.mobile_number}
ADR;type=WORK;type=pref:;;;${user.company?.address};;;
URL;CHARSET=UTF-8;type=LINK:${user.social_link?.website}
URL;CHARSET=UTF-8;type=GOOGLEMAP:${user.social_link?.google_map}
URL;CHARSET=UTF-8;type=FACEBOOK:${user.social_link?.facebook}
URL;CHARSET=UTF-8;type=WHATSAPPBUSINESS:${user?.whatsapp_number}
URL;CHARSET=UTF-8;type=INSTAGRAM:${user.social_link?.instagram};;;
END:VCARD`;

        // console.log(card);
        var file = new Blob(
            [card],
            { type: "text/vcard;charset=utf-8" }
        );
        FileSaver.saveAs(file, `${names[0] ?? ''}${names[1] ?? ''}.vcf`, true);
            toast.success("Contact info downloaded successfully.");
        }catch(error:any){
            // console.log(error);
            toast.error(error?.message ?? "Error saving contact.");
        }
        setSimLoad((prev) => ({ ...prev, vcard: false }));
    }

    return (
        <>
            <ConnectCard className="d-md-block d-none pb-4">
                <ConnectCoverSection background={availableImage('cover')} />
                <Row className="px-lg-5 px-3 mt-5">
                    <Col xxl={3} md={4}>
                        <UserProfilePicture
                            src={availableImage()}
                            className="img-thumbnail"
                        />
                    </Col>
                    <Col xxl={6} md={5}>
                        <h3 className="fw-bolder">{user?.name}</h3>
                        <p className="mb-0 text-capitalize">{user?.job_position}</p>
                        <div className="ps-0 mt-1">
                            <span className="text-theme-primary touched">{user?.connection_count}+ Connect</span>
                        </div>
                    </Col>
                    <Col xxl={3} md={3}>
                        <CompanyLogo src={availableImage('biz')} />
                        &nbsp;<span>{user?.company?.name}</span>
                    </Col>
                </Row>

                <ConnectButtonContainer className="mt-4">
                    <PrimaryButton
                        icon={<AddSvg />}
                        text={"Add Contact"}
                        style={{ maxWidth: '160px' }}
                        isLoading={simLoad.vcard}
                        onClick={(e) => getContact()}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <OutlinedButton
                        text={"Exchange Contact"}
                        style={{ maxWidth: '190px' }}
                        onClick={() => setModalActive(true)}
                    />
                </ConnectButtonContainer>
            </ConnectCard>

            {/* Profile Summary For Tab And Mobile Screen */}
            <div className="d-md-none d-block">
                <ConnectCoverSection background={availableImage('cover')} />
                <ConnectCard>
                    <div className="d-flex align-items-center">
                        <UserProfilePicture src={availableImage()} />
                        <div className="ms-3">
                            <h3 className="fw-bold mb-3">{user?.name}</h3>
                            <div>
                                <CompanyLogo src={availableImage('biz')} />
                                &nbsp;<span>{user?.company?.name}</span>
                            </div>
                            <p className="mb-0">{user?.job_position}</p>
                            <span className="text-theme-primary touched">{user?.connection_count}+ Connect</span>
                        </div>
                    </div>
                </ConnectCard>

                <ConnectCard className="p-4 mt-2">
                    <Row>
                        <Col xs={12} sm={6} className="mt-3">
                            <PrimaryButton
                                icon={<AddSvg />}
                                text={"Add Contact"}
                                isLoading={simLoad.vcard}
                                onClick={(e) => getContact()}
                            />
                        </Col>
                        <Col xs={12} sm={6} className="mt-3">
                            <OutlinedButton
                                text={"Exchange Contact"}
                                onClick={() => setModalActive(true)}
                            />
                        </Col>
                    </Row>
                </ConnectCard>

            </div>

            {/* Modal */}
            <ThemeModal 
                title="Exchange Contact" 
                active={modalActive} 
                setActive={() => setModalActive(false)}
            >
                <ExchangeContactsComponent onClose={() => setModalActive(false)} user={user} />
            </ThemeModal>
        </>
    )
}