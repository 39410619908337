import { Modal } from "react-bootstrap"
import { HeadLine } from "./styled";

type ModalProps = {
    title?: string;
    active: boolean;
    setActive?: (stat: boolean) => void;
    children: React.ReactNode;
};

export const ThemeModal:React.FC<ModalProps> = ({
    title,
    active,
    setActive,
    children
}) => {

    return (
        <Modal
            show={active} 
            onHide={() => (setActive && setActive(false))} 
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <HeadLine />
                <Modal.Title 
                    className="text-center fw-bolder"
                >{title}
                </Modal.Title>
                <div className="py-3">{children}</div>
            </Modal.Body>
        </Modal>
    );
}