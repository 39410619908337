import styled from "@emotion/styled";
import { Col, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export const SideBarColumn = styled.div`
  max-width: 17%;
  height: 100vh;
  padding-top: 6.7em;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1020;
  background-size: 130% !important;
  background-position-x: 10% !important;
  background-position-y: 107% !important;
  background-color: #090c11;
  background-repeat: no-repeat !important;
  transition: 0.8s ease;
  &.slide {
    left: -500;
    right: unset;
  }
  @media (max-width: 1328px) {
    padding-left: 10px !important;
    padding-right: 10px !important;

    width: 240px !important;
    min-width: 240px !important;
    max-width: 250px !important;
  }
`;
export const TopLogoNav = styled.div`
  width: 17%;
`;
export const MainCtnColumn = styled(Col)`
  position: relative;
 
`;
export const NavButton = styled.div`
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;
  span {
    top: 50%;
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: #000;
    &:nth-child(1) {
      top: 20%;
    }
    &:nth-child(3) {
      top: auto;
      bottom: 11%;
    }
  }
`;

export const SideBarLinkContainer = styled.div`
  // padding: 1.2rem;
`;

export const BrandLogoSection = styled(Navbar.Brand)`
  // display: flex;
  // align-self: center;

  @media (min-width: 574px) {
    justify-content: center;
    min-width: 320px;
  }
`;
type iconProps = {
  icon?: string;
  iconactive?: string | React.ReactNode;
};
export const SideBarLinks = styled(NavLink)<iconProps>(({ 
  iconactive = "#000", 
  icon = "#fff"
}) => (`
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 500;
  color: #fff !important;
  display: flex;
  transition: 0.5s ease;
  align-items: center;
  border-right-left: 0px;
  border-right: 10px solid transparent;
  padding-left: 5px;
  :hover {
    transition: 0.5s;
    background: rgba(255, 255, 255, 0.2);
  }
  text-decoration: none;
  margin-bottom: 10px;
  &.active {
    border-right: 10px solid #23ade5;
    background: #fff !important;
    color: #000 !important;
    .icon {
      background: ${iconactive !== "#000" ? "url(" + iconactive + ")" : iconactive };
    }
  }
  .icon {
    background-repeat: no-repeat;
    display: block;
    height: 32px;
    width: 32px;
    background-size: contain;
    background: url(${icon});
    transition: 0.5s ease;
  }
`));

export const HorizontalSpacer = styled.div`
  position: relative;
  height: ${({ height = "30px" }: SpacerStyledProp) => height};
  display: block;
  @media (max-width: 768px) {
    height: ${({ heightSm = "0px" }: SpacerStyledProp) => heightSm};
  }
`;

export interface SpacerStyledProp {
  height?: string;
  heightSm?: string;
}
