import { Card, Carousel, Col, Row } from "react-bootstrap"
import { ReactComponent as LeftIcon } from "../../../assets/svgs/arrow-left-circle.svg";
import { ReactComponent as RightIcon } from "../../../assets/svgs/arrow-right-circle.svg";
import { ReactComponent as DemoQRCode } from "../../../assets/svgs/demo-qr.svg";
import { ConnectCardsContainer } from "../../../containers/connect/connectCards";
import { CardTitle } from "./styled";
import QRCode from "react-qr-code";
import { ProfileData } from "../../../store/user/interface";
import { baseUrl, siteUrl } from "../../../utils/requests";
import { BoxedInfoInputComponent } from "components/views/boxedInfoInput";

export const Files: React.FC<{ user: ProfileData }> = ({ user }) => {

    return (
        <ConnectCardsContainer>
            <Row>
            { 
                (user?.file && user?.file.length > 0) ?
                    <Col md={12}>
                        <CardTitle className="fw-bold text-center text-lg-start">Files</CardTitle>
                        <div className="py-3">
                            {(user?.file) ? (
                            ((user?.file).map((res: any) => (
                                <a href={res.file} target="_blank">
                                    <BoxedInfoInputComponent
                                        title={res.title}
                                        value={res.file}
                                        disabled={true}
                                        containerProps={{style: { borderStyle: "dashed", borderColor: "#26A8E2" }}}
                                        titleProps={{style: { borderStyle: "dashed !important",  borderColor: "#26A8E2"}}}
                                    />
                                </a>
                            )))
                        ) : <>No file uploaded yet</>}
                        </div>
                    </Col>
                    : " "
                }
            </Row>
        </ConnectCardsContainer>
    )
}