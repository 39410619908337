import { Route, Routes } from 'react-router-dom';
import { EditProfilePage } from '../pages/dashboard/profile/editProfile';
import { UserConnectionPage } from '../pages/dashboard/connection';
import { QRCodePage } from '../pages/dashboard/qrcode';
import { SettingsPage } from '../pages/dashboard/settings';
import { ConnectHomePage } from '../pages/connect/home';

export const DashboardRoutes = () => {
    return (
        <Routes>   
          <Route path="/edit-profile" element={<EditProfilePage />} />
          <Route path="/connections" element={<UserConnectionPage />} />
          <Route path="/qrcode" element={<QRCodePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/preview/profile/:id" element={<ConnectHomePage />} />
        </Routes>
    )
}