import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums";
import { ReactComponent as Camera } from "../../../../assets/svgs/camera.svg";
import { ActionSection, IconWrapper, UserProfilePicture } from "../styled";
import { HiddenCropper } from "react-bootstrap-image-cropper";
import { useRef, useState } from "react";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useUploadProfilePictureMutation } from "../../../../store/user/api";
import { toast } from "react-toastify";
import { validationError } from '../../../../utils/validations';
import { Spinner } from "react-bootstrap";
import { ProfileData } from "../../../../store/user/interface";
import { baseUrl } from "../../../../utils/requests";

type IProps = {
  user?: ProfileData
}

export const EditProfilePicture = ({
  user
}: IProps) => {
  const triggerRef = useRef<any>();
  const [image, setImage] = useState<{url: string, file: any}>();
  const [update, { isLoading }] = useUploadProfilePictureMutation();


  const handleCropped = (file: any) => {
    let objectURL = URL.createObjectURL(file);
    setImage({url: objectURL, file: file});
  }

  const availableImage = () => {
    if (image) return image.url;
    if (user?.photo?.avatar) return user?.photo?.avatar;
    return require("../../../../assets/images/user-default-img.png")
  }

  const initUpload = () => {
    if (image) {
      const filename = "ProfilePictureImage.jpg";
      const fileObject = new File([image.file], filename, {
        type: 'image/jpg'
      });
      const payload = new FormData();
      payload.append('avatar', fileObject);
      payload.append('auth_id', user?.auth_id ?? '',)

      update(payload).unwrap().then((res) => {
        toast.success(res?.response);
        setTimeout(() => setImage(undefined), 2000);
      }).catch((error) => {
        toast.error("Please try again, something went wrong.");
      });
    }
  }

  return (
    <CardColumnsContainer
      colProps={{ lg: 12 }}
      title="Profile Picture"
      subTitle="Tap on the icon to change"
      classes="pb-3"
    >
      <div className="position-relative">
        <UserProfilePicture
          src={availableImage()}
          className="img-fluid"
          alt="name"
        />
        <ActionSection>
          {image && (
            <IconWrapper onClick={() => initUpload()}>
              {(isLoading) ? (<Spinner animation={"border"} size={"sm"}/>) : (<CloudUploadOutlinedIcon />)}
            </IconWrapper>
          )}
          &nbsp;&nbsp;
          <IconWrapper onClick={() => triggerRef?.current.trigger()}>
            <Camera width={"55%"}  />
          </IconWrapper>
        </ActionSection>
      </div>
      <HiddenCropper
        triggerRef={triggerRef}
        onCropped={handleCropped}
        cropOptions={{ aspect: 1 / 1, maxZoom: 10 }}
        outputOptions={{ maxWidth: 200, maxHeight: 200 }}
        previewOptions={{ width: 200, height: 200 }}
      />
    </CardColumnsContainer>
  );
};
