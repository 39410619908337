import { reactLocalStorage } from 'reactjs-localstorage';
import { useMemo } from "react";
import { setCredential, useIsLoading, useSelectCurrentUser, useSelectAccessToken } from ".";
import { useAppDispatch, useAppSelector } from "../hooks";
import { User } from './interface';

export const ph_photo = 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png';

export const useAuth = () => {
    const user = useAppSelector(useSelectCurrentUser);
    const token = useAppSelector(useSelectAccessToken);
    const isLoading = useAppSelector(useIsLoading);
    let SUser: User|null = null;
    let SToken: string | null = null;

    try{
        const storedUser = reactLocalStorage.get('@tap_it_user');
        const jsonStored = JSON.parse(storedUser);
        SToken = jsonStored ? jsonStored.access_token : (token || null);
        SUser = jsonStored ? jsonStored.user : null;
    } catch (error){
        console.log(error);
    }

    return useMemo(
        () => ({ user: user ? user : SUser, isLoading, token: SToken }),
        [user, SUser, isLoading, token, SToken]
    );
};


export const useLogout = () => {
    const dispatch = useAppDispatch();
    return () => {
        dispatch(setCredential({}))
        reactLocalStorage.clear();
    };
};