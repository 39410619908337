import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { PrimaryButton } from "../../../../components/buttons"
import { PhoneInputComponent } from '../../../../components/inputs/phoneInput';
import { ThemeInputComponent } from "../../../../components/inputs/themeInput"
import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums"
import { useUpdateProfileMutation } from '../../../../store/user/api';
import { EditProfileDetailsPayload, ProfileData } from '../../../../store/user/interface';
import { validationError } from '../../../../utils/validations';
import { setCredential } from "../../../../store/auth"
import { useDispatch } from "react-redux"

type IProps = {
    user?: ProfileData,
    access_token?: string|null
}
export const EditProfileDetails: React.FC<IProps> = ({
    user, access_token
}) => {
    const dispatch = useDispatch()
    const [updateProfile, { isLoading }] = useUpdateProfileMutation()
    const [form, setForm] = useState<EditProfileDetailsPayload>({
        auth_id: user?.auth_id ?? '',
        name: user?.name ?? '',
        username: user?.username ?? '',
        position: user?.job_position ?? '',
        whatsapp_number: user?.whatsapp_number ?? '',
        mobile_number: user?.mobile_number ?? ''
    });
    const [formError, setFormError] = useState({
        name: false, 
        username: false,
        position: false, 
        whatsapp_number: false, 
        mobile_number: false
    });

    const setValue = (param: keyof EditProfileDetailsPayload, value: string) => {
        setForm((prev: any) => ({ ...prev, [param]: value }));
        setFormError((prev: any) => ({ ...prev, [param]: false }));
    }

    const handleSubmit = () => {
        if (form?.name.length < 1 || form?.name.split(' ').length < 2) {
            setFormError((prev: any) => ({ ...prev, name: true }));
        } else if (form?.mobile_number.length && !isValidPhoneNumber(form?.mobile_number)) {
            setFormError((prev: any) => ({ ...prev, mobile_number: true }));
        } else if (form?.position.length && form?.position.length < 2) {
            setFormError((prev: any) => ({ ...prev, position: true }));
        } else if (form?.whatsapp_number.length && !isValidPhoneNumber(form?.whatsapp_number)) {
            setFormError((prev: any) => ({ ...prev, whatsapp_number: true }));
        } else {
            updateProfile(form).unwrap().then((res) => {
                dispatch(setCredential({user: {...user, ...form }, access_token }))
                toast.success(res?.response);
            }).catch((error) => {
                console.log(error);
                toast.error(error?.message ?? validationError(error?.data?.response));
            })
        }
    }

    return (
        <CardColumnsContainer
            colProps={{ lg: 12 }}
            title="Profile Details" subTitle="Please provide your details below"
        >
            <div>
                <div className="mb-3">
                    <ThemeInputComponent
                        placeholder="Fullname"
                        value={form?.name}
                        setValue={(value) => setValue('name', value)}
                        error={formError.name}
                        helperText="Both first & last name is required!."
                    />
                </div>
                <div className="mb-3">
                    <ThemeInputComponent
                        placeholder="Username"
                        value={form?.username}
                        setValue={(value) => setValue('username', value)}
                        error={formError.username}
                        helperText="Username is required!."
                    />
                </div>
                <div className="mb-3">
                    <ThemeInputComponent
                        placeholder="Position"
                        setValue={(value) => setValue('position', value)}
                        value={form?.position}
                        error={formError.position}
                        helperText="Position is required!"
                    />
                </div>
                <div className="mb-3">
                    <PhoneInputComponent
                        placeholder="Mobile Number"
                        type="phone"
                        setValue={(value) => setValue('mobile_number', value)}
                        value={form?.mobile_number}
                        error={formError.mobile_number}
                        helperText="Valid mobile number is required!"
                    />
                </div>
                <div className="mb-3">
                    <PhoneInputComponent
                        placeholder="WhatsApp Number"
                        setValue={(value) => setValue('whatsapp_number', value)}
                        value={form?.whatsapp_number}
                        error={formError.whatsapp_number}
                        helperText="Valid Whatsapp number is required!"
                    />
                </div>
                <div className="mb-2" >
                    <PrimaryButton 
                        text={"Update"} 
                        isLoading={isLoading} 
                        onClick={() => handleSubmit()}
                    />
                </div>
            </div>
        </CardColumnsContainer>
    )
}