import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import { authApi } from './auth/api';
import { userApi } from "./user/api"
import { productApi } from "./product/api"


export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [productApi.reducerPath]: productApi.reducer,
        auth: authReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(authApi.middleware)
        .concat(userApi.middleware)
        .concat(productApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;