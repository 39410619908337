import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import { ConnectHomePage } from "./pages/connect/home";
import { DashboardRoutes, AuthRoutes, MainRoutes } from './navigations'
import { initInterceptors } from "./utils/axiosQuery/axiosInterceptors";
import { ThemeProvider } from "@emotion/react";
import { useFetchColorThemeQuery } from "./store/user/api";
import { NotFoundPage } from "pages/landing/NotFound";

function App() {
  const { data: scheme } = useFetchColorThemeQuery();
  const theme = {
    dark: false,
    colors: {
      primary: "#23ade5",
      secondary: "#27316D",
      card: "#ffffff",
      btn_pbg: "#23ade5",
      btn_pb: "#23ade5",
      btn_pt: "#000",
      btn_sbg: "transparent",
      btn_sb: "#23ade5",
      btn_st: "#23ade5",
      btn_hpbg: "#000",
      btn_hpb: "#000",
      btn_hpt: "#23ade5",
      btn_hsbg: "#000",
      btn_hsb: "#000",
      btn_hst: "#23ade5",
    },
  };
  
  useEffect(() => { initInterceptors()});

  useEffect(() => {
    if (scheme?.data){
      const colorScheme = scheme?.data;
      if (colorScheme.primary_button?.background) theme.colors.primary = colorScheme.primary_button?.background;
      if (colorScheme.primary_button?.background) theme.colors.btn_pbg = colorScheme.primary_button?.background;
      if (colorScheme.primary_button?.border) theme.colors.btn_pb = colorScheme.primary_button?.border;
      if (colorScheme.primary_button?.text) theme.colors.btn_pt = colorScheme.primary_button?.text;
      if (colorScheme.secondary_button?.background) theme.colors.btn_sbg = colorScheme.secondary_button?.background;
      if (colorScheme.secondary_button?.border) theme.colors.btn_sb = colorScheme.secondary_button?.border;
      if (colorScheme.secondary_button?.text) theme.colors.btn_st = colorScheme.secondary_button?.text;
      if (colorScheme.primary_hover?.background) theme.colors.btn_hpbg = colorScheme.primary_hover?.background;
      if (colorScheme.primary_hover?.border) theme.colors.btn_hpb = colorScheme.primary_hover?.border;
      if (colorScheme.primary_hover?.text) theme.colors.btn_hpt = colorScheme.primary_hover?.text;
      if (colorScheme.secondary_hover?.background) theme.colors.btn_hsbg = colorScheme.secondary_hover?.background;
      if (colorScheme.secondary_hover?.border) theme.colors.btn_hsb = colorScheme.secondary_hover?.border;
      if (colorScheme.secondary_hover?.text) theme.colors.btn_hst = colorScheme.secondary_hover?.text;
    }
  }, [scheme, theme.colors]);
  

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/*" element={<MainRoutes />} />
        <Route path="auth/*" element={<AuthRoutes />} />
        <Route path="dashboard/*" element={<DashboardRoutes />} />
        <Route path="connect/:username" element={<ConnectHomePage />} />
        <Route element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;