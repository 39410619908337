import { Route, Routes } from 'react-router-dom';
import { RegisterPage } from "../pages/auth/register";
import { RegisterIndividualPage } from "../pages/auth/registerIndividual";
import RegisterCompanyPage from "../pages/auth/registerCompany";
import { ForgotPasswordPage } from "../pages/auth/forgotPassword";
import { ResetPasswordPage } from "../pages/auth/resetPassword";
import { CompleteRegistrationPage } from "../pages/auth/completeRegistration";
import LoginPage from "../pages/auth/login";
import { VerifyPage } from '../pages/auth/verify';
import { BaseViewWrapper } from 'containers/main/baseViewWrapper'

export const AuthRoutes = () => {
    return (
        <Routes>   
          <Route element={<BaseViewWrapper />}>   
            <Route path="/login" element={<LoginPage />} />
            {/* <Route path="/register" element={<RegisterPage />} /> */}
            {/* <Route path="/verify" element={<VerifyPage />} /> */}
            {/* <Route path="/register/individual" element={<RegisterIndividualPage />} /> */}
            {/* <Route path="/register/company" element={<RegisterCompanyPage />} /> */}
            {/* <Route path="/register/complete" element={<CompleteRegistrationPage />} /> */}
            <Route path="/password/forgot" element={<ForgotPasswordPage />} />
            <Route path="/password/reset/:token" element={<ResetPasswordPage />} />
          </Route>
        </Routes>
    )
}