import React from "react";
import { Card, CardProps, Col, ColProps } from "react-bootstrap"
import { Divider } from '../../components/views/styled';

type Props = {
    title?: string;
    subTitle?: string | React.ReactNode;
    colProps?: ColProps;
    cardProps?: CardProps;
    cardBodyProps?: any;
    divder?: boolean;
    children?: any;
    classes?: string;
    childContainerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
};

export const CardColumnsContainer = ({
    title,
    subTitle,
    colProps,
    children,
    divder,
    classes,
    childContainerProps
}: Props) => {
    return (
        <Col className="pb-4" {...colProps}>
            <Card style={{ borderRadius: 10 }}>
                <Card.Body className={`pb-0 ${classes}`}>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{subTitle}</Card.Text>
                    { divder ?? <Divider/> }
                    <div {...childContainerProps}>
                        {children}
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
}