import { FlexibleContainer } from "../styled";
import { BaseViewContainer } from "containers/main/baseViewContainer";
import { useViewProductQuery } from "store/product/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Product } from "../components/card";
import { ProductData } from "@store/product/interface";
import { SpinnerPreloader } from "components/preloader";
import { ReactComponent as ArrowCircleLeft } from "assets/svgs/arrow-circle-left.svg";
import { Button, BorderedButton } from "../styled";
import currencyFormatter from "utils/currencyFormatter";
import { useManageCartItems } from "store/product/cart";
import { toast } from "react-toastify";

export const ViewProductPage = () => {
    const { pathname } = useLocation();
    const { addItem, isLoading: isCartLoading } = useManageCartItems();
    const pathArray: string[] = pathname.trim().split("/")
    const productId = pathArray[pathArray.length - 1]
    const [product, setProduct] = useState<ProductData>();
    const { data, isLoading } = useViewProductQuery(productId);
    const [quantity, setQuantity] = useState(1);
    const navigate = useNavigate();

    const propsUrl = (product: ProductData, quantity: number | string) => {
        navigate('/checkout', { replace: true, state: { productId: product.guid, quantity: quantity } })
    }

    useEffect(() => {
        if (data && !data?.data) alert(data?.response);
        if (data?.data) setProduct(data?.data);
    }, [data]);


    const initCardAddItem = () => {
        if (product && quantity) {
            addItem({ ...product, quantity }).then((response) => {
                if (response) toast.success(response as string);
            }).catch((error) => {
                console.log(error);
                toast.error(error);
            })
        }
    }

    const toggleQuantityAmount = (action: 'add'|'minus') => {
        const max = 1000000000;
        const min = 1;
        let newQuantity = quantity;

        if(action === 'add' && quantity < max) newQuantity++;

        if(action === 'minus' && quantity > min) newQuantity--;

        setQuantity(newQuantity)
    }

    const goToCheckout = () => {
        let item = { ...product, quantity }
        navigate('/checkout', { state: { items: [item] } });
    }

    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <FlexibleContainer alignItems={false} className="row g-md-4 g-0" margin="1em auto 4em auto">
                <a href="/products" className="mb-md-1 mb-4"><ArrowCircleLeft /> Back</a>
                {(!product || isLoading) ?
                    <SpinnerPreloader height="50vh" />
                    :
                    <>
                        <div className="col-lg-5 col-md-6 col-sm-12 mb-md-0 mb-4">
                            <Product image={product?.image} />
                        </div>

                        <div className="col-lg-7 col-md-6 col-sm-12 mb-md-0 mb-5">
                            <h4 className="fw-bold">{product?.name}</h4>
                            <h5 style={{ color: "#23ADE5" }}>&#x20A6;{currencyFormatter(product?.amount)}</h5>
                            <div dangerouslySetInnerHTML={{ __html: product?.description }} />
                            <p className="mb-2">QTY</p>
                            <div className="input-group mb-3">
                                <span
                                    className="input-group-text bg-transparent touchable"
                                    onClick={() => (isLoading ? null : toggleQuantityAmount('add'))}
                                >+</span>
                                <input
                                    type="text"
                                    value={quantity}
                                    style={{ width: '12%', textAlign: 'center'}}
                                    className="bg-transparent border-1"
                                    placeholder="Quantity eg: 5"
                                    onChange={({ target }) => setQuantity(parseInt(target.value) ? parseInt(target.value) : 1)}
                                    readOnly={isLoading}
                                />
                                <span
                                    className="input-group-text bg-transparent touchable"
                                    onClick={() => (isLoading ? null : toggleQuantityAmount('minus'))}
                                >-</span>
                            </div>

                            <Button smWidth="100%" lgWidth="70%" onClick={goToCheckout}>Buy Now</Button><br /><br />
                            <BorderedButton smWidth="100%" lgWidth="70%" disabled={!product} onClick={initCardAddItem}>
                                { isCartLoading ? 'Adding to cart....' : 'Add to Cart'}
                            </BorderedButton>
                        </div>
                    </>
                }
            </FlexibleContainer>
        </BaseViewContainer>
    )
}