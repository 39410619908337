import { useRef, useState } from "react";
import { CardColumnsContainer } from "../../../../containers/dashboard/cardColums"
import { HiddenCropper } from "react-bootstrap-image-cropper";
import { Col, Row, Spinner } from "react-bootstrap";
import { CloseBtn, CloseBtnContainer, GalleryImage, IconWrapper } from "../styled";
import { ProfileData } from "../../../../store/user/interface";
import { toast } from "react-toastify";
import { validationError } from '../../../../utils/validations';
import { useUploadGalleryPictureMutation, useDeleteImageMutation } from "../../../../store/user/api";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { baseUrl } from "../../../../utils/requests";

type IProps = {
  user?: ProfileData
}

export const EditGallery = ({
    user
}: IProps) => {
    const triggerRef = useRef<any>();
    const [images, setImages] = useState<{ url: string, file: any }[]>([]);
    const [update, { isLoading }] = useUploadGalleryPictureMutation();
    const [deleImages, {isLoading: deleteLoading}] = useDeleteImageMutation();
    let removingImage = false;

    const handleCropped = (file: any) => {
        let objectURL = URL.createObjectURL(file);
        setImages((prev) => ([...prev, { url: objectURL, file: file }]));
    }

    const availableImages = () => {
        if (!removingImage){
            const galleryImages = (user?.photo?.gallery ?? []).map((img) => (img));
            const fetchedImages = (images ?? []).map((img) => img.url);
            return [...galleryImages, ...fetchedImages];
        }else{
            return [];
        }
    }

    const removeImage = (img: string) => {
        removingImage = true;
        const removeFromImages:{ url: string, file: any }[] = images.filter((value) => (value.url !== img));
        if(removeFromImages.length === images.length){
            deleImages({image: img}).unwrap().then((res) => {
                toast.success(res?.response);
            }).catch((error) => {
                console.log(error);
                toast.error(error?.message ?? validationError(error?.data?.response));
            }).finally(() => {
                removingImage = false;
            });
        }else{
            setImages(removeFromImages);
            toast.success("Image removed successfully");
            removingImage = false;
        }        
    }

    const initUpload = () => {
        if (images) {
            const payload = new FormData();
            for (let i = 0; i < images.length; i++) {
                const file = images[i].file;
                const filename = "GalleryImage_"+ i + ".jpg";
                const fileObject = new File([file], filename, {
                    type: 'image/jpg'
                });
                payload.append('gallery', fileObject);
            }
            update(payload).unwrap().then((res) => {
                toast.success(res?.response);
                setTimeout(() => setImages([]), 2000);
            }).catch((error) => {
                console.log(error);
                toast.error(error?.message ?? validationError(error?.data?.response));
            });
        }
    }


    
    return (
        <CardColumnsContainer
            colProps={{ lg: 12 }}
            title="Gallery" 
            subTitle={(<><span>Please upload picture into this gallery to be shown in your profile gallery </span><b>(434 x 434 preferably)</b></>)}
        >
            {(user?.photo?.gallery?.length || images.length) ? (
                <div className="py-3">
                    <div className="d-flex justify-content-start rounded ">
                        {((availableImages()).map((image, index) => (
                            <div className="border position-relative me-2" key={image}>
                                <CloseBtnContainer style={(deleteLoading) ? { opacity: '1 !important'} : undefined}>
                                    <CloseBtn className="btn btn-danger ml-auto" onClick={() => removeImage(image)}>
                                        {(deleteLoading) ? (<Spinner animation={"border"} size={"sm"} />) : 'x'}
                                    </CloseBtn>
                                </CloseBtnContainer>
                                <GalleryImage src={image} className="img-fluid" />
                            </div>
                        )))}
                    </div>
                </div>
            ) : null}
            
            <div className="pb-4 d-flex align-items-center">
                <button
                    className="btn p-3"
                    style={{ border: "1px dashed #eee" }}
                    onClick={() => ((isLoading || deleteLoading) ? null : triggerRef?.current.trigger())}
                >Upload Pictures png or jpeg Format</button>
                &nbsp;&nbsp;
                {(images.length) ? (
                    <IconWrapper onClick={() => ((isLoading || deleteLoading) ? null : initUpload())} >
                        {(isLoading || deleteLoading) ? (<Spinner animation={"border"} size={"sm"} />) : (<CloudUploadOutlinedIcon />)}
                    </IconWrapper>
                ) : null}
            </div>
            

            <HiddenCropper
                triggerRef={triggerRef}
                onCropped={handleCropped}
                cropOptions={{ aspect: 3 / 3, maxZoom: 10 }}
                outputOptions={{ maxWidth: 434, maxHeight: 434 }}
                previewOptions={{ width: 400, height: 300 }}
            />
        </CardColumnsContainer>
    )
}