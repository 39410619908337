import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axiosQuery/axiosBaseQuery';
import { baseUrl } from '../../utils/requests';
import { FetchProductResponse, Product } from './interface';


export const productApi = createApi({
    reducerPath: 'productApi',
    baseQuery: axiosBaseQuery({ baseUrl: `${baseUrl}/` }),
    tagTypes: ["products", "view"],
    endpoints: (builder) => ({
        fetchProducts: builder.query<FetchProductResponse, void>({
            query: (credentials) => ({
                url: 'product',
                method: 'GET',
            }),
            providesTags: ['products']
        }),
        viewProduct: builder.query<Product, string|void>({
            query: (id) => ({
                url: `product/${id}`,
                method: 'GET',
            }),
        }),
        order: builder.mutation({
            query: (credentials) => ({
                url: `order`,
                method: 'POST',
                body: credentials
            }),
        }),
    })
});

export const {
   useFetchProductsQuery,
   useViewProductQuery,
   useOrderMutation
} = productApi;

    