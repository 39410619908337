import { Container, Spinner } from "react-bootstrap"

type IProps = {
    text?: string;
    containerStyle?: React.CSSProperties
}
export const LoadingViewContainer = ({
    text = "Loading information...",
    containerStyle
}: IProps) => {
    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '85vh', ...containerStyle }}>
            <div>
                <div className="d-flex justify-content-center align-items-center h-100 mb-3">
                    <Spinner animation="border" />
                </div>
                <p className="text-center">{text}</p>
            </div>
        </Container>
    )
}