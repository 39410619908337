import { TextTitle, SubText, FlexibleContainer } from "../styled"
import { BaseViewContainer } from "containers/main/baseViewContainer";
import { useFetchProductsQuery } from "store/product/api";
import { Product } from "../components/card";
import { SpinnerPreloader } from "components/preloader";

export const ProductPage = () => {
    const { data, isLoading } = useFetchProductsQuery();

    return (
        <BaseViewContainer backgroundWaterMark={true}>
            <FlexibleContainer
                // alignItems={false} 
                className="row g-5 mx-auto"
                margin="3.5em 0 4em 0"
            >
                <div className="text-center">
                    <TextTitle className="mb-3">High Quality || <br /> High Performance</TextTitle>
                    <SubText>Tap It Digital RFID’s products are powered by NFC and are also compatible with iOS & Android <br /> (no special App required.) Just with one-time payment (no subscription), you are ready to go.</SubText>
                </div>
                {(!data?.data || isLoading) ?
                    <SpinnerPreloader />
                    :
                    data?.data?.data.map((product, index) => (
                        <div className="col-md-6 col-sm-12 mb-md-0 mb-5" key={index}>
                            <a href={`/products/${product.guid}`} style={{ textDecoration: "none", color: "inherit" }}>
                                <Product
                                    name={product.name}
                                    amount={product.amount}
                                    image={product.image}
                                />
                            </a>
                        </div>
                    ))}
            </FlexibleContainer>
        </BaseViewContainer>
    )
}