import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, NavbarBrand, RightMenuItems, NavbarToggle } from '../styled';
import { ReactComponent as Logo } from "assets/svgs/dark-icon.svg";
import { ReactComponent as User } from "assets/svgs/profile.svg";
import { ReactComponent as Cart } from "assets/svgs/shopping-cart.svg";
import { useLocation } from 'react-router-dom';
import { useManageCartItems, CartProductData } from "store/product/cart";
import { Button } from "../../../pages/landing/styled"

export const NavigationBar = () => {
    const { pathname } = useLocation()
    const [url, setUrl]: any = useState(null);
    const { getItems, items: carting, isLoading } = useManageCartItems();
    const [items, setCartItems] = useState<CartProductData[]>([]);


    useEffect(() => {
        setUrl(pathname)
        setCartItems(getItems())
    }, [carting, isLoading]);

    return (
        <Navbar collapseOnSelect expand="md" bg="white" variant="light" className="py-md-3 py-1 fixed-top">
            <Container style={{ maxWidth: "1150px"}}>
                <NavbarBrand href="/"><Logo /></NavbarBrand>
                <NavbarToggle aria-controls="responsive-navbar-nav" />
                <Navbar.Offcanvas
                    id="offcanvasNavbar-expand-md"
                    aria-labelledby="offcanvasNavbarLabel-expand-md"
                    placement="start"
                >
                    <Offcanvas.Header closeButton className="float-end" />
                    <Offcanvas.Body>
                        <Navbar.Collapse id="responsive-navbar-nav" className="ms-md-5 ms-0">
                            <Nav className="me-auto ms-md-3 ms-0">
                                <NavLink className={url === "/" ? "active" : ""} href="/">Home</NavLink>
                                <NavLink className={url === "/products" ? "active" : ""} href="/products">Products</NavLink>
                                <NavLink href="#faq">FAQ</NavLink>
                            </Nav>
                        </Navbar.Collapse>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
                <RightMenuItems>
                    <NavLink className="d-flex" href="/auth/login"><User />&nbsp;Login</NavLink>
                    <NavLink className={`d-flex ${url} === "/cart" ? "active" : ""`} href="/cart"><Cart />&nbsp;Cart&nbsp;
                        {items && items.length > 0 ? (<span className="badge bg-danger rounded-pill">{items.length}</span>) : <></>}
                    </NavLink>
                    <Button href="/products" className="ms-4">Get Smart Card Now</Button>
                </RightMenuItems>
            </Container>
        </Navbar>
    );
}